import React from 'react'

const ItemQty = ({
  totalItems,
  totalTakeQty,
  totalReturnQty,
  totalSaleQty,
}) => {
  return (
    <div>
      <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
        <div>Total Items: {totalItems}</div>
        <div className='mt-1'>Total Item Qty (Take): {totalTakeQty}</div>
        <div className='mt-1'>Total Item Qty (Return): {totalReturnQty}</div>
        <div className='mt-1'>Total Item Qty (Sell): {totalSaleQty}</div>
      </div>
    </div>
  )
}

export default ItemQty
