import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Modal } from 'react-bootstrap'
import MainNewConference from './NewConference'
import { useAuth } from '../../../context/auth'

const MainConferenceList = () => {
  const navigate = useNavigate()
  const [auth] = useAuth()
  const userId = auth?.user?.id
  const groupId = auth?.user?.group
  const [conferences, setConferences] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [editingAttendance, setEditingAttendance] = useState(null)

  useEffect(() => {
    const fetchConferences = async () => {
      try {
        const { data } = await axios.get('/api/v1/main/conference/lists', {
          params: { groupId: groupId },
        })
        if (data.success) {
          setConferences(data.data)
        }
      } catch (error) {
        console.error('Failed to fetch conferences:', error)
      }
    }

    fetchConferences()
  }, [])

  const handleOpenModal = () => {
    setIsModalVisible(true)
  }

  const handleCloseModal = () => {
    setIsModalVisible(false)
  }

  const handleFormSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        userId,
        groupId,
      }

      const { data } = await axios.post(
        '/api/v1/main/conference/add-event',
        payload
      )
      if (data.success) {
        setConferences((prev) => [data.data, ...prev])
        handleCloseModal()
      }
    } catch (error) {
      console.error('Failed to add conference:', error)
    }
  }

  const handleAttendanceUpdate = async (id, newAttendance) => {
    try {
      const { data } = await axios.put(
        `/api/v1/main/conference/update-attendance/${id}`,
        { attendance: newAttendance }
      )
      if (data.success) {
        setConferences((prev) =>
          prev.map((conf) =>
            conf._id === id ? { ...conf, attendance: newAttendance } : conf
          )
        )
      }
    } catch (error) {
      console.error('Failed to update attendance:', error)
    }
  }

  const handleAttendanceDoubleClick = (id) => {
    setEditingAttendance(id)
  }

  const handleAttendanceChange = (id, value) => {
    setConferences((prev) =>
      prev.map((conf) =>
        conf._id === id ? { ...conf, attendance: value } : conf
      )
    )
  }

  const handleAttendanceBlur = (id, value) => {
    const originalValue = conferences.find(
      (conf) => conf._id === id
    )?.attendance
    if (parseInt(value, 10) !== originalValue) {
      handleAttendanceUpdate(id, value)
    }
    setEditingAttendance(null)
  }

  const handleAttendanceKeyDown = (id, event, value) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAttendanceBlur(id, value)
    }
  }

  const handleStatusChange = async (id, newStatus) => {
    try {
      const { data } = await axios.put(
        `/api/v1/main/conference/update-status/${id}`,
        { status: newStatus }
      )
      if (data.success) {
        setConferences((prev) =>
          prev.map((conf) =>
            conf._id === id ? { ...conf, status: newStatus } : conf
          )
        )
      }
    } catch (error) {
      console.error('Failed to update status:', error)
    }
  }

  const toggleStatus = (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 2 : 1
    const confirmChange = window.confirm('Confirm Update Status?')

    if (confirmChange) {
      handleStatusChange(id, newStatus)
    }
  }
  const handleReturnHome = () => navigate('/main/dashboard')

  return (
    <Layout title={'Conference'}>
      <div className='pt-3 pb-5 mx-3'>
        <div className='d-flex mb-2 justify-content-between align-items-center'>
          <h5
            className='mb-0'
            style={{
              color: '#556B2F',
            }}
          >
            Conference
          </h5>

          <div className='d-flex ms-auto'>
            <button
              type='button'
              className='btn btn-outline-success btn-sm me-3'
              onClick={handleOpenModal}
            >
              <i className='bi bi-plus-circle'></i> Conference
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={handleReturnHome}
            >
              <i className='bi bi-house-fill'></i>
            </button>
          </div>
        </div>

        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>Date</th>
                <th scope='col'>Event</th>
                <th scope='col'>Attendance</th>
                <th scope='col'>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {conferences.map((conference) => (
                <tr key={conference._id}>
                  <td>
                    {new Date(conference.recordDate).toLocaleDateString()}
                  </td>
                  <td>{conference.event}</td>
                  <td
                    onDoubleClick={() =>
                      handleAttendanceDoubleClick(conference._id)
                    }
                  >
                    {editingAttendance === conference._id ? (
                      <input
                        type='number'
                        value={conference.attendance || 0}
                        onChange={(e) =>
                          handleAttendanceChange(conference._id, e.target.value)
                        }
                        onBlur={(e) =>
                          handleAttendanceBlur(conference._id, e.target.value)
                        }
                        onKeyDown={(e) =>
                          handleAttendanceKeyDown(
                            conference._id,
                            e,
                            e.target.value
                          )
                        }
                        className='form-control'
                      />
                    ) : (
                      conference.attendance || 0
                    )}
                  </td>
                  <td
                    onClick={() =>
                      toggleStatus(conference._id, conference.status)
                    }
                    style={{
                      color: conference.status === 1 ? 'green' : 'grey',
                      cursor: 'pointer',
                    }}
                  >
                    {conference.status === 1 ? 'Active' : 'Inactive'}
                  </td>
                  <td className='text-end'>
                    <button
                      className='btn btn-sm btn-outline-success me-3'
                      onClick={() => navigate(`sale/${conference._id}`)}
                    >
                      Sale
                    </button>
                    <button
                      className='btn btn-sm btn-outline-primary'
                      onClick={() => navigate(`record/${conference._id}`)}
                    >
                      Record
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={isModalVisible} onHide={handleCloseModal} centered>
        <Modal.Body>
          <MainNewConference
            onFormSubmit={handleFormSubmit}
            onCancel={handleCloseModal}
          />
        </Modal.Body>
      </Modal>
    </Layout>
  )
}

export default MainConferenceList
