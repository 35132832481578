import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import useIsMobile from '../../../hooks/useIsMobile'
import { useAuth } from '../../../context/auth'

const MainStatisticList = () => {
  const navigate = useNavigate()
  const [auth] = useAuth()
  const userId = auth?.user?.id
  const groupId = auth?.user?.group

  const isMobile = useIsMobile()
  const [groupedLists, setGroupedLists] = useState({})

  useEffect(() => {
    const fetchList = async () => {
      if (!groupId) {
        console.error('No groupId found. Please log in.')
        navigate('/login')
        return
      }

      try {
        const { data } = await axios.get('/api/v1/main/statistic/lists', {
          params: { groupId }, // Pass groupId
        })

        groupByDate(data)
      } catch (error) {
        console.error(
          'Failed to fetch lists:',
          error.response?.data || error.message
        )
      }
    }

    fetchList()
  }, [groupId, navigate])

  const groupByDate = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const date = new Date(item.recordDate).toISOString().split('T')[0]
      if (!acc[date]) acc[date] = []
      acc[date].push(item)
      return acc
    }, {})
    setGroupedLists(groupedData)
  }

  const handleReturnHome = () => {
    if (auth?.user?.role === 2) {
      navigate('/main/dashboard')
    } else {
      navigate('/user/dashboard')
    }
  }

  return (
    <Layout title={'Rekod Statistik'}>
      <div className={`pt-3 ${isMobile ? 'mx-2' : 'mx-3'}`}>
        <div
          className={`d-flex mb-3 ${
            isMobile
              ? 'flex-column'
              : 'justify-content-between align-items-center'
          }`}
        >
          <div
            className={`w-100 ${isMobile ? 'mt-2' : ''}`}
            style={{
              textAlign: isMobile ? 'left' : 'inherit',
            }}
          >
            <h5
              className='mb-0'
              style={{
                color: '#556B2F',
              }}
            >
              Team Statistics
            </h5>
          </div>

          {!isMobile && (
            <div className='d-flex justify-content-end w-100'>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          )}
        </div>

        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col' colSpan='2' className='text-center'>
                  Non-Christian (P/U)
                </th>
                <th
                  scope='col'
                  className='text-center'
                  rowSpan='2'
                  style={{
                    verticalAlign: 'middle',
                  }}
                >
                  Christian (P/U)
                  <br />
                  (Seeking)
                </th>
                <th
                  scope='col'
                  className='text-center'
                  rowSpan='2'
                  style={{
                    verticalAlign: 'middle',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Home Meeting <div>(P/U)</div>
                </th>
                <th
                  scope='col'
                  className='text-center'
                  rowSpan='2'
                  style={{
                    verticalAlign: 'middle',
                  }}
                >
                  Baptism
                </th>
                <th
                  scope='col'
                  className='text-center'
                  rowSpan='2'
                  style={{
                    verticalAlign: 'middle',
                  }}
                >
                  Team
                </th>
                <th scope='col' className='text-center' rowSpan='2'></th>
              </tr>
              <tr>
                <th
                  scope='col'
                  className='text-center'
                  style={{
                    borderTop: '1px solid #000',
                    borderRight: '1px solid #000',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Very Open
                </th>
                <th
                  scope='col'
                  className='text-center'
                  style={{
                    borderTop: '1px solid #000',
                  }}
                >
                  Open
                </th>
              </tr>
            </thead>

            <tbody>
              {Object.keys(groupedLists).length > 0 ? (
                Object.entries(groupedLists).map(([date, items]) => {
                  const totalNonChristianVOP = items.reduce(
                    (sum, item) => sum + (item.nonChristianVOP || 0),
                    0
                  )
                  const totalNonChristianVOU = items.reduce(
                    (sum, item) => sum + (item.nonChristianVOU || 0),
                    0
                  )
                  const totalNonChristianOP = items.reduce(
                    (sum, item) => sum + (item.nonChristianOP || 0),
                    0
                  )
                  const totalNonChristianOU = items.reduce(
                    (sum, item) => sum + (item.nonChristianOU || 0),
                    0
                  )
                  const totalChristianP = items.reduce(
                    (sum, item) => sum + (item.christianP || 0),
                    0
                  )
                  const totalChristianU = items.reduce(
                    (sum, item) => sum + (item.christianU || 0),
                    0
                  )
                  const totalHomeP = items.reduce(
                    (sum, item) => sum + (item.homeP || 0),
                    0
                  )
                  const totalHomeU = items.reduce(
                    (sum, item) => sum + (item.homeU || 0),
                    0
                  )
                  const totalBaptism = items.reduce(
                    (sum, item) => sum + (item.baptism || 0),
                    0
                  )

                  return (
                    <React.Fragment key={date}>
                      <tr>
                        <td
                          colSpan='8'
                          className='bg-light fw-bold text-center'
                          style={{ color: '#0c432a' }}
                        >
                          {new Date(date).toLocaleDateString('en-GB', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })}
                        </td>
                      </tr>
                      {items.map((item) => (
                        <tr key={item._id}>
                          <td className='text-center'>
                            {item.nonChristianVOP > 0 ||
                            item.nonChristianVOU > 0
                              ? `${item.nonChristianVOP || 0}/${
                                  item.nonChristianVOU || 0
                                }`
                              : '-'}
                          </td>
                          <td className='text-center'>
                            {item.nonChristianOP > 0 || item.nonChristianOU > 0
                              ? `${item.nonChristianOP || 0}/${
                                  item.nonChristianOU || 0
                                }`
                              : '-'}
                          </td>
                          <td className='text-center'>
                            {item.christianP > 0 || item.christianU > 0
                              ? `${item.christianP || 0}/${
                                  item.christianU || 0
                                }`
                              : '-'}
                          </td>
                          <td className='text-center'>
                            {item.homeP > 0 || item.homeU > 0
                              ? `${item.homeP || 0}/${item.homeU || 0}`
                              : '-'}
                          </td>
                          <td className='text-center'>
                            {item.baptism > 0 ? item.baptism : '-'}
                          </td>
                          <td
                            className='text-center'
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {item.teamId ? item.teamId.teamName : 'No Team'}
                          </td>
                          <td>
                            {item.placeCat === 1
                              ? 'B.P'
                              : item.placeCat === 2
                              ? 'Tamu'
                              : ''}
                          </td>
                        </tr>
                      ))}
                      <tr className='fw-bold'>
                        <td className='text-center'>
                          {totalNonChristianVOP}/{totalNonChristianVOU}
                        </td>
                        <td className='text-center'>
                          {totalNonChristianOP}/{totalNonChristianOU}
                        </td>
                        <td className='text-center'>
                          {totalChristianP}/{totalChristianU}
                        </td>
                        <td className='text-center'>
                          {totalHomeP}/{totalHomeU}
                        </td>
                        <td className='text-center'>{totalBaptism}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </React.Fragment>
                  )
                })
              ) : (
                <tr>
                  <td colSpan='7' className='text-center'>
                    Tiada rekod
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default MainStatisticList
