import React, { useEffect, useState } from 'react'
import axios from 'axios'

const SaleLog = ({ itemId, itemDetails, lastUpdate }) => {
  const [logs, setLogs] = useState([])

  useEffect(() => {
    if (itemId) {
      fetchLogs()
    }
  }, [itemId])

  const fetchLogs = async () => {
    try {
      const response = await axios.get(
        `/api/v1/main/book-record/sales-logs/${itemId}`
      )
      if (response.data.success) {
        setLogs(response.data.data)
      } else {
        console.error(response.data.message)
        setLogs([])
      }
    } catch (error) {
      console.error('Failed to fetch sale logs:', error)
      setLogs([])
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  }

  return (
    <>
      <div className='text-center h6 shadow-sm pb-3 bg-white rounded'>
        Sale Logs for {itemDetails.itemCode} {itemDetails.description}
      </div>
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Team</th>
              <th>Sale Qty</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log, index) => (
              <tr key={index}>
                <td>{formatDate(log.recordDate)}</td>
                <td>{log.teamId?.teamName || 'No Team'}</td>
                <td>{log.qty}</td>
                <td>
                  {log.placeCat === 1
                    ? 'PB.'
                    : log.placeCat === 2
                    ? 'Tamu'
                    : 'Conf.'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default SaleLog
