import React from 'react'
import { useAuth } from '../../context/auth'
import { Link, useNavigate } from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap'
import '../../../src/styles/custom.css'

const AdminHeader = () => {
  const [auth, setAuth] = useAuth()
  const navigate = useNavigate()

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: '',
    })
    localStorage.removeItem('auth')
    navigate('/login', { state: { loggedOut: true } })
  }

  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      variant='dark'
      fixed='top'
      style={{ backgroundColor: '#556B2F' }}
    >
      <Container>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto'>
            {auth?.user && (
              <Link
                className='navbar-brand'
                style={{ color: 'white', textDecoration: 'none' }}
                to={'/admin/dashboard'}
              >
                <i className='bi bi-house-fill'></i>
              </Link>
            )}
          </Nav>
          <Nav className='me-3' style={{ color: 'white' }}>
            {auth?.user?.name}
          </Nav>
          <Nav>
            {auth?.user ? (
              <Nav.Link onClick={handleLogout}>
                <i className='bi bi-box-arrow-right'></i>
              </Nav.Link>
            ) : (
              <Nav.Link href='/login'>Login</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default AdminHeader
