import React, { useState, useEffect } from 'react'
import axios from 'axios'

const AdminCreateUser = ({ onSuccess, onCancel }) => {
  const [name, setName] = useState('')
  const [username, setUsername] = useState('')
  const [role, setRole] = useState(1)
  const [status, setStatus] = useState(1)
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [groups, setGroups] = useState([])
  const [teams, setTeams] = useState([])
  const [selectedGroup, setSelectedGroup] = useState('')
  const [selectedTeam, setSelectedTeam] = useState('')
  const [usernameError, setUsernameError] = useState('')

  useEffect(() => {
    if (role === 2 || role === 3) {
      fetchGroups()
    }
  }, [role])

  const fetchGroups = async () => {
    try {
      const { data } = await axios.get('/api/v1/admin/user/groups')
      setGroups(data.groups)
    } catch (error) {
      console.error('Error fetching groups:', error)
    }
  }

  const fetchTeams = async (groupId) => {
    try {
      const { data } = await axios.get(
        `/api/v1/admin/user/groups/${groupId}/teams`
      )
      setTeams(data.teams)
    } catch (error) {
      console.error('Error fetching teams:', error)
    }
  }

  const handleGroupChange = (e) => {
    const groupId = e.target.value
    setSelectedGroup(groupId)
    setSelectedTeam('')
    fetchTeams(groupId)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const payload = {
        name,
        username,
        password,
        role,
        status,
      }

      if (role === 2 || role === 3) {
        payload.groupId = selectedGroup
        payload.teamId = selectedTeam
      }

      const { data } = await axios.post('/api/v1/admin/user/new', payload)

      if (data?.success) {
        if (onSuccess) {
          onSuccess()
        }
      } else {
        setUsernameError(data?.message || 'An error occurred.')
      }
    } catch (error) {
      if (error.response?.data?.message) {
        setUsernameError(error.response.data.message)
      } else {
        console.error(error)
      }
    }
  }

  return (
    <>
      <div className='modal-header'>
        <h5 className='modal-title'>Add New User</h5>
        <button type='button' className='btn-close' onClick={onCancel}></button>
      </div>
      <div className='modal-body'>
        <form onSubmit={handleSubmit}>
          <div className='mb-3'>
            <label className='mb-2 text-muted' htmlFor='name'>
              Name
            </label>
            <input
              id='name'
              value={name}
              onChange={(e) => setName(e.target.value)}
              type='text'
              className='form-control'
              required
            />
          </div>

          <div className='mb-3'>
            <label className='mb-2 text-muted' htmlFor='username'>
              Username
            </label>
            <input
              id='username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type='text'
              className={`form-control ${usernameError ? 'is-invalid' : ''}`}
              required
            />
            {usernameError && (
              <div className='invalid-feedback' style={{ color: 'red' }}>
                {usernameError}
              </div>
            )}
          </div>

          <div className='mb-3'>
            <label className='mb-2 text-muted'>Role</label>
            <select
              className='form-select'
              value={role}
              onChange={(e) => setRole(Number(e.target.value))}
            >
              <option value={1}>Admin</option>
              <option value={2}>Main Coordinator</option>
              <option value={3}>Coordinator</option>
            </select>
          </div>
          {role === 2 || role === 3 ? (
            <div className='mb-3'>
              <label className='mb-2 text-muted'>Group</label>
              <select
                className='form-select'
                value={selectedGroup}
                onChange={handleGroupChange}
                disabled={role === 1}
              >
                <option value=''>Select Group</option>
                {groups.map((group) => (
                  <option key={group._id} value={group._id}>
                    {group.groupName}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          {role === 2 || role === 3 ? (
            <div className='mb-3'>
              <label className='mb-2 text-muted'>Team</label>
              <select
                className='form-select'
                value={selectedTeam}
                onChange={(e) => setSelectedTeam(e.target.value)}
                disabled={!selectedGroup}
              >
                <option value=''>Select Team</option>
                {teams.map((team) => (
                  <option key={team._id} value={team._id}>
                    {team.teamName}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          <div className='mb-3'>
            <label className='mb-2 text-muted'>Status</label>
            <div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='status'
                  id='active'
                  value={1}
                  checked={status === 1}
                  onChange={() => setStatus(1)}
                />
                <label className='form-check-label' htmlFor='active'>
                  Active
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='status'
                  id='inactive'
                  value={2}
                  checked={status === 2}
                  onChange={() => setStatus(2)}
                />
                <label className='form-check-label' htmlFor='inactive'>
                  Inactive
                </label>
              </div>
            </div>
          </div>
          <div className='mb-3'>
            <label className='mb-2 text-muted' htmlFor='password'>
              Password
            </label>
            <div className='input-group'>
              <input
                id='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                className='form-control'
                required
              />
              <button
                type='button'
                className='btn btn-outline-secondary'
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>
          <div className='text-end'>
            <button type='submit' className='btn btn-primary me-4 btn-sm'>
              Submit
            </button>
            <button
              type='button'
              className='btn btn-secondary btn-sm'
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default AdminCreateUser
