import React, { useState, useEffect } from 'react'
import Layout from '../../../../components/Layout/Layout'
import axios from 'axios'
import { Dropdown } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useIsMobile from '../../../../hooks/useIsMobile'
import { useAuth } from '../../../../context/auth'

const ResponseCardEditTamu = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { id } = useParams()
  const [auth] = useAuth()
  const groupId = auth?.user?.group

  const [formData, setFormData] = useState({
    submitDate: '',
    name: '',
    address: '',
    phone: '',
    race: '',
    gender: null,
    language: null,
    otherLang: '',
    categoryOne: null,
    categoryTwo: null,
    remark: '',
    responseOption: [],
    selectedBooks: [],
  })

  const [places, setPlaces] = useState([])
  const [selectedPlace, setSelectedPlace] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [packingListData, setPackingListData] = useState([])

  useEffect(() => {
    const fetchResponseCard = async () => {
      try {
        const { data } = await axios.get(`/api/v1/user/response/${id}`)
        const formattedDate = data.submitDate
          ? new Date(data.submitDate).toISOString().split('T')[0]
          : ''
        setFormData({
          ...data,
          submitDate: formattedDate,
          selectedBooks: data.bookRecord || [],
        })
        setSelectedPlace(data.place || null)
      } catch (error) {
        console.error('Failed to fetch response card:', error)
        toast.error('Failed to fetch response card.')
      }
    }

    const fetchPlaces = async () => {
      try {
        if (!auth?.user?.group) {
          toast.error('User group ID is missing.')
          return
        }

        const { data } = await axios.get(
          `/api/v1/user/response/tamu-place/${auth.user.group}`
        )
        setPlaces(data)
      } catch (error) {
        console.error('Failed to fetch places:', error)
        toast.error('Failed to fetch places.')
      }
    }

    fetchResponseCard()
    fetchPlaces()
  }, [id, auth])

  const handlePlaceChange = (e) => {
    setSelectedPlace(e.target.value)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleCheckboxChange = (e) => {
    const value = parseInt(e.target.value, 10)
    setFormData((prev) => ({
      ...prev,
      responseOption: e.target.checked
        ? [...prev.responseOption, value]
        : prev.responseOption.filter((opt) => opt !== value),
    }))
  }

  const handleSearch = async (e) => {
    const term = e.target.value
    setSearchTerm(term)

    if (term.length > 3) {
      try {
        const { data } = await axios.get('/api/v1/user/response/search-book', {
          params: {
            search: term,
            groupId: groupId,
          },
        })
        setPackingListData(data)
      } catch (error) {
        console.error(
          'Failed to fetch packing list data:',
          error.response?.data?.message || error.message
        )
      }
    } else {
      setPackingListData([])
    }
  }

  const handleSelect = (item) => {
    setFormData((prev) => ({
      ...prev,
      selectedBooks: [...prev.selectedBooks, { ...item, qty: 1 }],
    }))
    setSearchTerm('')
    setPackingListData([])
  }

  const handleQtyChange = (index, value) => {
    const updatedBooks = [...formData.selectedBooks]
    updatedBooks[index].qty = value
    setFormData((prev) => ({
      ...prev,
      selectedBooks: updatedBooks,
    }))
  }

  const handleRadioChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!formData.name) {
      toast.error('* are required fields.')
      return
    }

    const updatedData = {
      ...formData,
      place: selectedPlace,
      bookRecord: formData.selectedBooks.map((book) => ({
        packingListData: book._id,
        itemCode: book.itemCode,
        description: book.description,
        price: book.price,
        qty: book.qty,
      })),
    }

    try {
      await axios.put(`/api/v1/user/response/tamu-update/${id}`, updatedData)
      toast.success('Response card updated successfully!')
      navigate('/user/tamu/respons')
    } catch (error) {
      console.error(
        'Failed to update response card:',
        error.response?.data || error.message
      )
      toast.error('Failed to update response card. Please try again.')
    }
  }

  const handleRemoveRow = async (index, book) => {
    const confirmDelete = window.confirm('Confirm Delete?')
    if (!confirmDelete) return

    try {
      await axios.delete(`/api/v1/user/response/remove-sale/${book}`, {
        data: {
          contactRecordId: id,
        },
      })
      setFormData((prev) => ({
        ...prev,
        selectedBooks: prev.selectedBooks.filter((_, idx) => idx !== index),
      }))
    } catch (error) {
      console.error(
        'Failed to delete book:',
        error.response?.data || error.message
      )
      toast.error('Failed to remove book. Please try again.')
    }
  }

  const handleReturnHome = () => {
    if (auth?.user?.role === 2) {
      navigate('/main/dashboard')
    } else {
      navigate('/user/dashboard')
    }
  }

  const handleCancel = () => {
    navigate('/user/tamu/respons')
  }

  return (
    <Layout title={'Edit Kad Respon'}>
      <div className='container pt-4 mb-5'>
        <div
          className={`d-flex mb-3 ${
            isMobile
              ? 'flex-column'
              : 'justify-content-between align-items-center'
          }`}
        >
          {isMobile && (
            <div className='d-flex justify-content-end w-100'>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm me-2'
                onClick={handleCancel}
              >
                Rekod Kad Respon
              </button>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          )}

          <div
            className={`w-100 ${isMobile ? 'mt-2' : ''}`}
            style={{
              textAlign: isMobile ? 'left' : 'inherit',
            }}
          >
            <h5
              className='mb-0'
              style={{
                color: '#556B2F',
              }}
            >
              Edit Kad Respon (Tamu)
            </h5>
          </div>

          {!isMobile && (
            <div className='d-flex justify-content-end w-100'>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm me-4'
                onClick={handleCancel}
              >
                Rekod Kad Respon
              </button>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit}>
          <div className='mb-3'>
            <label htmlFor='submitDate' className='form-label'>
              Tarikh<span style={{ color: 'red' }}>*</span> :
            </label>
            <input
              type='date'
              id='submitDate'
              name='submitDate'
              className='form-control'
              value={formData.submitDate}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className='my-3'>
            <label className='form-label'>
              Tempat<span style={{ color: 'red' }}>*</span> :
            </label>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: isMobile ? '0.5rem' : '1rem',
              }}
            >
              {places.length > 0 ? (
                places.map((place) => (
                  <label
                    key={place._id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                  >
                    <input
                      type='radio'
                      name='place'
                      value={place._id}
                      checked={selectedPlace === place._id}
                      onChange={handlePlaceChange}
                    />
                    {place.placeName}
                  </label>
                ))
              ) : (
                <p>No places</p>
              )}
            </div>
          </div>

          <div className='mb-2'>
            Bagaimanakah anda ingin lebih mengenali sabda Tuhan (sila tandakan):
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: isMobile ? '0.5rem' : '1rem',
            }}
          >
            {[
              { label: 'Kunjungan Rumah', value: 1 },
              { label: 'Pameran Buku', value: 2 },
              { label: 'Persidangan Khas', value: 3 },
              { label: 'Tempahan Buku', value: 4 },
            ].map((option) => (
              <label
                key={option.value}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: isMobile ? '0.3rem' : '0.5rem',
                }}
              >
                <input
                  type='checkbox'
                  value={option.value}
                  checked={formData.responseOption.includes(option.value)}
                  onChange={handleCheckboxChange}
                  style={{ width: '15px', height: '15px' }}
                />
                {option.label}
              </label>
            ))}
          </div>
          <div className='my-3'>
            <label htmlFor='name' className='form-label'>
              Nama<span style={{ color: 'red' }}>*</span> :
            </label>
            <input
              type='text'
              id='name'
              name='name'
              className='form-control'
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>

          <div className={`card ${isMobile ? 'p-2' : 'p-3'} mb-3`}>
            <div className='mb-3'>
              <label htmlFor='bookSearch' className='form-label'>
                Buku:
              </label>
              <Dropdown
                show={packingListData.length > 0 && searchTerm.length > 3}
              >
                <Dropdown.Toggle
                  as='input'
                  type='text'
                  id='bookSearch'
                  className='form-control'
                  placeholder='Cari Nama Buku / Item Code'
                  value={searchTerm}
                  onChange={handleSearch}
                  autoComplete='off'
                />
                <Dropdown.Menu
                  style={{
                    width: isMobile ? 'calc(100vw - 30px)' : '100%',
                    maxWidth: '100%',
                    left: isMobile ? '15px' : '0',
                    right: isMobile ? '15px' : '0',
                    position: 'absolute',
                    zIndex: 1050,
                    maxHeight: '300px',
                    overflowY: 'auto',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {packingListData.map((item) => {
                    const isSelected = formData.selectedBooks.some(
                      (book) => book.itemCode === item.itemCode
                    )
                    return (
                      <Dropdown.Item
                        key={item._id}
                        onClick={() => !isSelected && handleSelect(item)}
                        disabled={isSelected}
                        style={{
                          cursor: isSelected ? 'not-allowed' : 'pointer',
                          color: isSelected ? 'gray' : 'inherit',
                        }}
                      >
                        {item.itemCode} - {item.description}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div>
              {isMobile ? (
                <div className='table-responsive'>
                  <table className='table table-bordered'>
                    <thead>
                      <tr>
                        <th>Buku</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.selectedBooks.length > 0 ? (
                        formData.selectedBooks.map((book, index) => (
                          <tr key={index}>
                            <td>
                              <div className='d-flex justify-content-between align-items-start'>
                                <span>
                                  {book.itemCode} {book.description}
                                </span>
                                <span
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  RM {book.price.toFixed(2)}
                                </span>
                              </div>
                              <div className='d-flex justify-content-between align-items-center mt-1'>
                                <label>Qty:</label>
                                <input
                                  type='number'
                                  min='1'
                                  value={book.qty}
                                  onFocus={(e) => e.target.select()}
                                  onChange={(e) =>
                                    handleQtyChange(index, e.target.value)
                                  }
                                  className='form-control me-4 ms-2'
                                />
                                <i
                                  className='bi bi-trash3'
                                  style={{
                                    color: '#dc3545',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    handleRemoveRow(index, book.itemCode)
                                  }
                                ></i>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan='2' className='text-center'>
                            Tiada buku dipilih
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <table className='table table-bordered'>
                  <thead>
                    <tr>
                      <th>Buku</th>
                      <th className='text-end'>Harga</th>
                      <th>Qty</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.selectedBooks.length > 0 ? (
                      formData.selectedBooks.map((book, index) => (
                        <tr key={index}>
                          <td>
                            {book.itemCode} {book.description}
                          </td>
                          <td className='text-end'>{book.price.toFixed(2)}</td>
                          <td>
                            <input
                              type='number'
                              min='1'
                              value={book.qty}
                              onFocus={(e) => e.target.select()}
                              onChange={(e) =>
                                handleQtyChange(index, e.target.value)
                              }
                              className='form-control'
                            />
                          </td>
                          <td className='text-center'>
                            <i
                              className='bi bi-trash3'
                              style={{ color: '#dc3545', cursor: 'pointer' }}
                              onClick={() =>
                                handleRemoveRow(index, book.itemCode)
                              }
                            ></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan='4' className='text-center'>
                          Tiada buku dipilih
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          <div className='mb-3'>
            <label htmlFor='address' className='form-label'>
              Alamat:
            </label>
            <textarea
              id='address'
              name='address'
              className='form-control'
              rows='1'
              value={formData.address}
              onChange={handleInputChange}
            ></textarea>
          </div>

          <div className='mb-3'>
            <label htmlFor='phone' className='form-label'>
              No. Tel:
            </label>
            <input
              type='text'
              id='phone'
              name='phone'
              className='form-control'
              value={formData.phone}
              onChange={handleInputChange}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='race' className='form-label'>
              Bangsa:
            </label>
            <input
              type='text'
              id='race'
              name='race'
              className='form-control'
              value={formData.race}
              onChange={handleInputChange}
            />
          </div>

          <div
            className='mb-3'
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'flex-start' : 'center',
              gap: isMobile ? '0.5rem' : '1rem',
            }}
          >
            <label className='form-label' style={{ margin: 0 }}>
              Jantina:
            </label>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: isMobile ? '0.6rem' : '1rem',
              }}
            >
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: isMobile ? '0.2rem' : '0.5rem',
                }}
              >
                <input
                  type='radio'
                  name='gender'
                  value='1'
                  checked={formData.gender === 1}
                  onChange={() => handleRadioChange('gender', 1)}
                />
                Lelaki
              </label>
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: isMobile ? '0.2rem' : '0.5rem',
                }}
              >
                <input
                  type='radio'
                  name='gender'
                  value='2'
                  checked={formData.gender === 2}
                  onChange={() => handleRadioChange('gender', 2)}
                />
                Perempuan
              </label>
            </div>
          </div>

          <div
            className='mb-3'
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'flex-start' : 'center',
              gap: isMobile ? '0.5rem' : '1rem',
            }}
          >
            <label
              className='form-label'
              style={{
                margin: 0,
              }}
            >
              Bahasa:
            </label>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: isMobile ? '0.6rem' : '1rem',
              }}
            >
              {[
                { label: 'BM', value: 1 },
                { label: 'Cina', value: 2 },
                { label: 'Inggeris', value: 3 },
                { label: 'Lain', value: 4 },
              ].map((lang) => (
                <label
                  key={lang.value}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: isMobile ? '0.2rem' : '0.5rem',
                  }}
                >
                  <input
                    type='radio'
                    name='language'
                    value={lang.value}
                    checked={formData.language === lang.value}
                    onChange={() => handleRadioChange('language', lang.value)}
                  />
                  {lang.label}
                </label>
              ))}
            </div>
          </div>

          {formData.language === 4 && (
            <div
              className='mb-3'
              style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'flex-start' : 'center',
                gap: isMobile ? '0.5rem' : '1rem',
              }}
            >
              <label
                htmlFor='otherLang'
                className='form-label'
                style={{
                  margin: 0,
                }}
              >
                Specify:
              </label>
              <input
                type='text'
                id='otherLang'
                className='form-control'
                value={formData.otherLang}
                onChange={handleInputChange}
              />
            </div>
          )}

          <div
            className='mb-3'
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'flex-start' : 'center',
              gap: isMobile ? '0.5rem' : '1rem',
            }}
          >
            <label
              className='form-label'
              style={{
                margin: 0,
                marginBottom: 0,
              }}
            >
              Category I:
            </label>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: isMobile ? '0.6rem' : '1rem',
              }}
            >
              {[
                { label: 'Adult', value: 1 },
                { label: 'Campus', value: 2 },
                { label: 'Youth', value: 3 },
                { label: 'Children', value: 4 },
              ].map((cat) => (
                <label
                  key={cat.value}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: isMobile ? '0.2rem' : '0.5rem',
                  }}
                >
                  <input
                    type='radio'
                    name='categoryOne'
                    value={cat.value}
                    checked={formData.categoryOne === cat.value}
                    onChange={() => handleRadioChange('categoryOne', cat.value)}
                  />
                  {cat.label}
                </label>
              ))}
            </div>
          </div>

          <div
            className='mb-3'
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'flex-start' : 'center',
              gap: isMobile ? '0.5rem' : '1rem',
            }}
          >
            <label
              className='form-label'
              style={{
                margin: 0,
                marginBottom: 0,
              }}
            >
              Category II:
            </label>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: isMobile ? '0.6rem' : '1rem',
              }}
            >
              {[
                { label: 'Saint', value: 1 },
                { label: 'Seeking Christian', value: 2 },
                { label: 'Gospel Friend', value: 3 },
                { label: 'New Believer', value: 4 },
                { label: 'Elderly', value: 5 },
              ].map((cat) => (
                <label
                  key={cat.value}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: isMobile ? '0.2rem' : '0.5rem',
                  }}
                >
                  <input
                    type='radio'
                    name='categoryTwo'
                    value={cat.value}
                    checked={formData.categoryTwo === cat.value}
                    onChange={() => handleRadioChange('categoryTwo', cat.value)}
                  />
                  {cat.label}
                </label>
              ))}
            </div>
          </div>

          <div className='mb-3'>
            <label htmlFor='remark' className='form-label'>
              Catatan:
            </label>
            <textarea
              id='remark'
              name='remark'
              className='form-control'
              value={formData.remark}
              onChange={handleInputChange}
            ></textarea>
          </div>

          <div className='text-end pt-3'>
            <button
              type='submit'
              className='btn btn-outline-warning btn-sm me-4'
            >
              Kemas kini
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={handleCancel}
            >
              Batal
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default ResponseCardEditTamu
