import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'

const AdminSummaryConference = () => {
  const [groupedConferences, setGroupedConferences] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchConferences = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/admin/summary/groups/conference'
        )
        if (data.success) {
          groupByDate(data.data)
        }
      } catch (error) {
        console.error('Failed to fetch conferences:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchConferences()
  }, [])

  const groupByDate = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const date = new Date(item.recordDate).toISOString().split('T')[0] // Get date part
      if (!acc[date]) acc[date] = []
      acc[date].push(item)
      return acc
    }, {})
    setGroupedConferences(groupedData)
  }

  return (
    <Layout title={'Conference'}>
      <div className='pt-3 pb-5 mx-3'>
        <div className='d-flex mb-2 justify-content-between align-items-center'>
          <h5
            className='mb-0'
            style={{
              color: '#556B2F',
            }}
          >
            Summary Conference
          </h5>
        </div>

        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>Team</th>
                <th scope='col'>Event</th>
                <th className='text-center'>Attendance</th>
                <th className='text-center'>Total Qty</th>
                <th className='text-end'>Total Sale</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan='5' className='text-center'>
                    <div className='spinner-border text-success' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : Object.entries(groupedConferences).length > 0 ? (
                Object.entries(groupedConferences).map(([date, events]) => (
                  <React.Fragment key={date}>
                    <tr>
                      <td colSpan='5' className='fw-bold bg-light text-center'>
                        {new Date(date).toLocaleDateString('en-GB', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })}
                      </td>
                    </tr>
                    {events.map((conference) => (
                      <tr key={conference._id}>
                        <td>{conference.groupName || 'No Team'}</td>
                        <td>{conference.event}</td>
                        <td className='text-center'>
                          {conference.attendance || 0}
                        </td>
                        <td className='text-center'>
                          {conference.totalQty || 0}
                        </td>
                        <td className='text-end'>
                          {conference.totalSale?.toFixed(2) || '0.00'}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan='5' className='text-center'>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default AdminSummaryConference
