import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import useIsMobile from '../../../hooks/useIsMobile'
import { useAuth } from '../../../context/auth'

const ResponseList = () => {
  const navigate = useNavigate()
  const [auth] = useAuth()
  const userId = auth?.user?.id
  const isMobile = useIsMobile()
  const [lists, setLists] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const fetchList = async () => {
      try {
        if (!userId) {
          console.error('No userId found. Please log in.')
          navigate('/login')
          return
        }

        const { data } = await axios.get(`/api/v1/user/response/lists`, {
          params: { userId },
        })

        setLists(data.lists)
        setTotalRecords(data.totalRecords)
      } catch (error) {
        console.error(
          'Failed to fetch lists:',
          error.response?.data || error.message
        )
      }
    }

    fetchList()
  }, [auth, navigate])

  const handleNewRespons = () => {
    navigate('new')
  }

  const filteredLists = lists.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item._id.slice(-6).toLowerCase().includes(searchTerm.toLowerCase())
  )

  const handleDelete = async (id) => {
    if (window.confirm('Confirm Delete?')) {
      try {
        await axios.delete(`/api/v1/user/response/delete/${id}`)
        setLists((prevLists) => prevLists.filter((item) => item._id !== id))
        toast.success('Deleted successfully')
      } catch (error) {
        console.error('Failed to delete response:', error)
        toast.error('Failed to delete.')
      }
    }
  }

  const handleReturnHome = () => {
    if (auth?.user?.role === 2) {
      navigate('/main/dashboard')
    } else {
      navigate('/user/dashboard')
    }
  }

  const handleEdit = (id) => {
    navigate(`edit/${id}`)
  }

  return (
    <Layout title={'Rekod Kad Respon'}>
      <div className={`pt-3 ${isMobile ? 'mx-2' : 'mx-3'}`}>
        <div
          className={`d-flex mb-2 ${
            isMobile
              ? 'flex-column'
              : 'justify-content-between align-items-center'
          }`}
        >
          {isMobile && (
            <div className='d-flex justify-content-end w-100'>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm me-2'
                onClick={handleNewRespons}
              >
                <i className='bi bi-plus-circle'></i> Kad Respon
              </button>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          )}

          <div
            className={`w-100 ${isMobile ? 'mt-2' : ''}`}
            style={{
              textAlign: isMobile ? 'left' : 'inherit',
            }}
          >
            <h5
              className='mb-0'
              style={{
                color: '#556B2F',
              }}
            >
              Rekod Kad Respon (PB.)
            </h5>
          </div>

          {!isMobile && (
            <div className='d-flex justify-content-end w-100'>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm me-4'
                onClick={handleNewRespons}
              >
                <i className='bi bi-plus-circle'></i> Kad Respon
              </button>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          )}
        </div>

        <div className='mb-2 fw-bold'>Jumlah Rekod: {totalRecords} </div>
        <div className='mb-3 d-flex align-items-center'>
          <input
            type='text'
            className='form-control'
            placeholder='Cari Nama atau ID'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            type='button'
            className='btn btn-outline-secondary'
            onClick={() => setSearchTerm('')}
            disabled={!searchTerm}
          >
            Clear
          </button>
        </div>

        <table className='table'>
          <thead>
            {isMobile ? (
              <tr>
                <th scope='col'>Tarikh</th>
                <th scope='col'>Nama</th>
                <th></th>
              </tr>
            ) : (
              <tr>
                <th scope='col'>ID</th>
                <th scope='col'>Tarikh</th>
                <th scope='col'>Nama</th>
                <th scope='col'>Bangsa</th>
                <th scope='col'>Bahasa</th>
                <th scope='col'>Category</th>
                <th scope='col'>Tempat</th>
                <th></th>
              </tr>
            )}
          </thead>

          <tbody>
            {!isMobile ? (
              filteredLists.length > 0 ? (
                filteredLists.map((item) => (
                  <React.Fragment key={item._id}>
                    <tr>
                      <td>{item._id.slice(-6)}</td>
                      <td>
                        {new Date(item.submitDate).toLocaleDateString('en-GB', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })}
                      </td>
                      <td
                        className='fw-bold'
                        style={{
                          color:
                            item.gender === 1
                              ? '#2f4483'
                              : item.gender === 2
                              ? '#78003c'
                              : 'inherit',
                        }}
                      >
                        {item.name}
                      </td>
                      <td>{item.race}</td>
                      <td>
                        {(() => {
                          switch (item.language) {
                            case 1:
                              return 'BM'
                            case 2:
                              return 'Cina'
                            case 3:
                              return 'Inggeris'
                            case 4:
                              return `Lain (${item.otherLang || 'N/A'})`
                            default:
                              return ''
                          }
                        })()}
                      </td>
                      <td>
                        {(() => {
                          switch (item.categoryOne) {
                            case 1:
                              return 'Adult'
                            case 2:
                              return 'Campus'
                            case 3:
                              return 'Youth'
                            case 4:
                              return 'Children'
                            default:
                              return ''
                          }
                        })()}{' '}
                        |{' '}
                        {(() => {
                          switch (item.categoryTwo) {
                            case 1:
                              return 'Saint'
                            case 2:
                              return 'Seeking Christian'
                            case 3:
                              return 'Gospel Friend'
                            case 4:
                              return 'New Believer'
                            case 5:
                              return 'Elderly'
                            default:
                              return ''
                          }
                        })()}
                      </td>
                      <td>{item.place ? item.place.placeName : '-'}</td>
                      <td className='text-end'>
                        <button
                          type='button'
                          className='btn btn-outline-primary btn-sm'
                          onClick={() => handleEdit(item._id)}
                        >
                          <i className='bi bi-pencil-square'></i>
                        </button>
                        <button
                          type='button'
                          className='btn btn-outline-danger btn-sm ms-3'
                          onClick={() => handleDelete(item._id)}
                        >
                          <i className='bi bi-trash-fill'></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan='8'>
                        <div className='mb-1 d-flex justify-content-between'>
                          <div>
                            <i
                              className='bi bi-book me-2'
                              style={{ color: '#556B2F' }}
                            ></i>
                            {item.bookRecord.length > 0
                              ? item.bookRecord.map((book, index) => (
                                  <span key={index}>
                                    {book.description} - {book.qty}
                                    {index < item.bookRecord.length - 1 && ', '}
                                  </span>
                                ))
                              : '-'}
                          </div>
                        </div>
                        <div className='mb-1'>
                          <i
                            className='bi bi-house-fill me-2'
                            style={{ color: '#556B2F' }}
                          ></i>
                          <span className='me-4'>{item.address || '-'}</span>
                          <span className='d-inline-flex align-items-center'>
                            <i
                              className='bi bi-telephone-fill me-2'
                              style={{ color: '#556B2F' }}
                            ></i>
                            {item.phone || '-'}
                          </span>
                        </div>
                        <div>
                          <i
                            className='bi bi-card-list me-2'
                            style={{ color: '#556B2F' }}
                          ></i>
                          {item.remark || '-'}
                        </div>
                        <div>
                          {item.responseOption &&
                            item.responseOption.length > 0 && (
                              <div>
                                {item.responseOption.map((option, index) => {
                                  let optionLabel = ''
                                  switch (option) {
                                    case 1:
                                      optionLabel = 'Kunjungan Rumah'
                                      break
                                    case 2:
                                      optionLabel = 'Pameran Buku'
                                      break
                                    case 3:
                                      optionLabel = 'Persidangan Khas'
                                      break
                                    case 4:
                                      optionLabel = 'Tempahan Buku'
                                      break
                                    default:
                                      optionLabel = ''
                                  }
                                  return (
                                    <span key={index}>
                                      <i className='bi bi-check'></i>
                                      {optionLabel}
                                      {index < item.responseOption.length - 1 &&
                                        ', '}
                                    </span>
                                  )
                                })}
                              </div>
                            )}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan='9' className='text-center'>
                    Tiada rekod
                  </td>
                </tr>
              )
            ) : filteredLists.length > 0 ? (
              filteredLists.map((item) => (
                <React.Fragment key={item._id}>
                  <tr>
                    <td>
                      {new Date(item.submitDate).toLocaleDateString('en-GB', {
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit',
                      })}
                    </td>
                    <td
                      className='fw-bold'
                      style={{
                        color:
                          item.gender === 1
                            ? '#2f4483'
                            : item.gender === 2
                            ? '#78003c'
                            : 'inherit',
                      }}
                    >
                      {item.name}
                    </td>
                    <td className='text-end' style={{ whiteSpace: 'nowrap' }}>
                      <i
                        className='bi bi-pencil-square'
                        style={{
                          color: 'blue',
                          cursor: 'pointer',
                          marginRight: '10px',
                        }}
                        onClick={() => handleEdit(item._id)}
                      ></i>
                      <i
                        className='bi bi-trash-fill'
                        style={{ color: 'red', cursor: 'pointer' }}
                        onClick={() => handleDelete(item._id)}
                      ></i>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan='3'>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          {(() => {
                            switch (item.categoryOne) {
                              case 1:
                                return 'Adult'
                              case 2:
                                return 'Campus'
                              case 3:
                                return 'Youth'
                              case 4:
                                return 'Children'
                              default:
                                return ''
                            }
                          })()}{' '}
                          |{' '}
                          {(() => {
                            switch (item.categoryTwo) {
                              case 1:
                                return 'Saint'
                              case 2:
                                return 'Seeking Christian'
                              case 3:
                                return 'Gospel Friend'
                              case 4:
                                return 'New Believer'
                              case 5:
                                return 'Elderly'
                              default:
                                return ''
                            }
                          })()}
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                          ID: {item._id.slice(-6)}
                        </div>
                      </div>
                      <div>
                        Place: {item.place ? item.place.placeName : '-'}
                      </div>
                      <div>
                        <i
                          className='bi bi-book me-2'
                          style={{ color: '#556B2F' }}
                        ></i>
                        {item.bookRecord.length > 0
                          ? item.bookRecord.map((book, index) => (
                              <span key={index}>
                                {book.description} - {book.qty}
                                {index < item.bookRecord.length - 1 && ', '}
                              </span>
                            ))
                          : '-'}
                      </div>
                      <div>
                        <i
                          className='bi bi-house-fill me-2'
                          style={{ color: '#556B2F' }}
                        ></i>
                        <span>{item.address || '-'}</span>
                        <span className='ms-3'>
                          <i
                            className='bi bi-telephone-fill me-2'
                            style={{ color: '#556B2F' }}
                          ></i>
                          {item.phone || '-'}
                        </span>
                      </div>
                      <div>
                        <i
                          className='bi bi-card-list me-2'
                          style={{ color: '#556B2F' }}
                        ></i>
                        {item.remark || '-'}
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan='3' className='text-center'>
                  Tiada rekod
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default ResponseList
