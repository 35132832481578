import React, { useState, useEffect } from 'react'
import Layout from '../../../components/Layout/Layout'
import AdminCreateGroup from './CreateGroup'
import AdminCreateTeam from './CreateTeam'
import { toast } from 'react-toastify'
import AdminEditGroup from './EditGroup'
import AdminEditTeam from './EditTeam'
import { useNavigate } from 'react-router-dom'

const AdminGroupList = () => {
  const navigate = useNavigate()

  const [showGroupModal, setShowGroupModal] = useState(false)
  const [showTeamModal, setShowTeamModal] = useState(false)
  const [selectedGroupId, setSelectedGroupId] = useState(null)
  const [groups, setGroups] = useState([])

  const handleShowGroupModal = () => setShowGroupModal(true)
  const handleCloseGroupModal = () => setShowGroupModal(false)

  const handleShowTeamModal = (groupId) => {
    setSelectedGroupId(groupId)
    setShowTeamModal(true)
  }

  const handleCloseTeamModal = () => {
    setSelectedGroupId(null)
    setShowTeamModal(false)
  }

  const [showEditModal, setShowEditModal] = useState(false)
  const [editingGroupId, setEditingGroupId] = useState(null)
  const [showEditTeamModal, setShowEditTeamModal] = useState(false)
  const [editingTeamDetails, setEditingTeamDetails] = useState({
    groupId: null,
    teamId: null,
  })

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await fetch('/api/v1/admin/group/list')
        if (response.ok) {
          const data = await response.json()
          setGroups(data)
        } else {
          toast.error('Failed to fetch groups')
        }
      } catch (error) {
        toast.error('Error fetching groups')
      }
    }

    fetchGroups()
  }, [])

  const handleAddGroup = (newGroup) => {
    setGroups((prevGroups) => [...prevGroups, newGroup])
  }

  const handleAddTeam = (groupId, newTeam) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group._id === groupId
          ? {
              ...group,
              teams: [...(group.teams || []), newTeam],
            }
          : group
      )
    )
  }

  const handleShowEditModal = (groupId) => {
    setEditingGroupId(groupId)
    setShowEditModal(true)
  }

  const handleCloseEditModal = () => {
    setEditingGroupId(null)
    setShowEditModal(false)
  }

  const handleUpdateGroup = (updatedGroup) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group._id === updatedGroup._id
          ? { ...group, ...updatedGroup, teams: group.teams || [] }
          : group
      )
    )
  }

  const handleShowEditTeamModal = (groupId, teamId) => {
    setEditingTeamDetails({ groupId, teamId })
    setShowEditTeamModal(true)
  }

  const handleCloseEditTeamModal = () => {
    setEditingTeamDetails({ groupId: null, teamId: null })
    setShowEditTeamModal(false)
  }

  const handleUpdateTeam = (updatedTeam) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.teams?.find((team) => team._id === updatedTeam._id)
          ? {
              ...group,
              teams: group.teams.map((team) =>
                team._id === updatedTeam._id ? updatedTeam : team
              ),
            }
          : group
      )
    )
  }

  const handleReturnHome = () => {
    navigate('/admin/dashboard')
  }

  return (
    <Layout title={'Group / Team'}>
      <div className='pt-3 mx-3'>
        <div className='text-end mb-3'>
          <button
            type='button'
            className='btn btn-outline-success btn-sm mx-3'
            onClick={handleShowGroupModal}
          >
            <i className='bi bi-plus-circle'></i> Group
          </button>
          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={handleReturnHome}
          >
            <i className='bi bi-house-fill'></i>
          </button>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>No.</th>
              <th scope='col'>Group Name</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group, index) => (
              <React.Fragment key={group._id}>
                <tr>
                  <td>{index + 1}</td>
                  <td>{group.groupName}</td>
                  <td
                    style={{
                      color: group.status === 1 ? 'green' : 'grey',
                    }}
                  >
                    {group.status === 1 ? 'Active' : 'Inactive'}
                  </td>
                  <td className='text-end'>
                    <button
                      className='btn btn-sm btn-outline-success me-3'
                      onClick={() => handleShowTeamModal(group._id)}
                    >
                      <i className='bi bi-plus-circle'></i> Team
                    </button>
                    <button
                      className='btn btn-sm btn-outline-primary'
                      onClick={() => handleShowEditModal(group._id)}
                    >
                      <i className='bi bi-pencil-square'></i>
                    </button>
                  </td>
                </tr>
                {group.teams?.length > 0 && (
                  <tr>
                    <td colSpan='4' style={{ padding: 0 }}>
                      <table className='table mb-0'>
                        <tbody>
                          {group.teams.map((team) => (
                            <tr key={team._id}>
                              <td></td>
                              <td></td>
                              <td
                                style={{
                                  color: team.status === 1 ? 'green' : 'grey',
                                }}
                              >
                                {team.teamName}
                              </td>
                              <td className='text-end'>
                                <button
                                  className='btn btn-sm btn-outline-primary'
                                  onClick={() =>
                                    handleShowEditTeamModal(group._id, team._id)
                                  }
                                >
                                  <i className='bi bi-pencil-square'></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <AdminCreateGroup
        show={showGroupModal}
        onClose={handleCloseGroupModal}
        onGroupCreated={handleAddGroup}
      />
      <AdminCreateTeam
        show={showTeamModal}
        onClose={handleCloseTeamModal}
        groupId={selectedGroupId}
        onTeamCreated={(newTeam) => handleAddTeam(selectedGroupId, newTeam)}
      />

      <AdminEditGroup
        show={showEditModal}
        onClose={handleCloseEditModal}
        groupId={editingGroupId}
        onGroupUpdated={handleUpdateGroup}
      />

      <AdminEditTeam
        show={showEditTeamModal}
        onClose={handleCloseEditTeamModal}
        teamId={editingTeamDetails.teamId}
        onTeamUpdated={handleUpdateTeam}
      />
    </Layout>
  )
}

export default AdminGroupList
