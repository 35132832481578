import React, { useState, useEffect } from 'react'
import Layout from '../../../../components/Layout/Layout'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import useIsMobile from '../../../../hooks/useIsMobile'
import { useAuth } from '../../../../context/auth'

const EditStatisticTamu = () => {
  const navigate = useNavigate()
  const [auth] = useAuth()

  const isMobile = useIsMobile()

  const { id } = useParams()
  const [formData, setFormData] = useState({
    recordDate: '',
    nonChristianVOP: 0,
    nonChristianVOU: 0,
    nonChristianOP: 0,
    nonChristianOU: 0,
    christianP: 0,
    christianU: 0,
    homeP: 0,
    homeU: 0,
    baptism: 0,
  })

  useEffect(() => {
    const fetchRecord = async () => {
      try {
        const { data } = await axios.get(`/api/v1/user/statistic/${id}`)
        setFormData({
          recordDate: data.recordDate.split('T')[0],
          nonChristianVOP: data.nonChristianVOP,
          nonChristianVOU: data.nonChristianVOU,
          nonChristianOP: data.nonChristianOP,
          nonChristianOU: data.nonChristianOU,
          christianP: data.christianP,
          christianU: data.christianU,
          homeP: data.homeP,
          homeU: data.homeU,
          baptism: data.baptism,
        })
      } catch (error) {
        console.error('Failed to fetch record:', error)
        toast.error('Failed to fetch record. Please try again.')
      }
    }

    fetchRecord()
  }, [id])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: name === 'recordDate' ? value : Number(value),
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await axios.put(`/api/v1/user/statistic/update/${id}`, formData)
      navigate('/user/tamu/statistics')
    } catch (error) {
      console.error('Failed to update record:', error)
      toast.error('Failed to update record. Please try again.')
    }
  }

  const handleCancel = () => {
    navigate('/user/tamu/statistics')
  }

  const handleReturnHome = () => {
    if (auth?.user?.role === 2) {
      navigate('/main/dashboard')
    } else {
      navigate('/user/dashboard')
    }
  }

  return (
    <Layout title={'Edit Statistik'}>
      <div className='container pt-4 mb-5'>
        <div
          className={`d-flex mb-3 ${
            isMobile
              ? 'flex-column'
              : 'justify-content-between align-items-center'
          }`}
        >
          {isMobile && (
            <div className='d-flex justify-content-end w-100'>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm me-2'
                onClick={handleCancel}
              >
                Rekod Statistik
              </button>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          )}

          <div
            className={`w-100 ${isMobile ? 'mt-2' : ''}`}
            style={{
              textAlign: isMobile ? 'left' : 'inherit',
            }}
          >
            <h5
              className='mb-0'
              style={{
                color: '#556B2F',
              }}
            >
              Edit Statistik (Tamu)
            </h5>
          </div>

          {!isMobile && (
            <div className='d-flex justify-content-end w-100'>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm me-4'
                onClick={handleCancel}
              >
                Rekod Statistik
              </button>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit}>
          <div className='mb-3'>
            <label htmlFor='recordDate' className='form-label'>
              Date:
            </label>
            <input
              type='date'
              id='recordDate'
              name='recordDate'
              value={formData.recordDate}
              onChange={handleInputChange}
              className='form-control'
              required
            />
          </div>

          {/* Non-Christian Very Open */}
          <div className='mb-3'>
            <label className='form-label'>
              Non-Christian (P/U) - Very Open
            </label>
            <div className='row g-3 align-items-center'>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='nonChristianVOP' className='me-2'>
                  P:
                </label>
                <input
                  type='number'
                  id='nonChristianVOP'
                  name='nonChristianVOP'
                  value={formData.nonChristianVOP}
                  onChange={handleInputChange}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='nonChristianVOU' className='me-2'>
                  U:
                </label>
                <input
                  type='number'
                  id='nonChristianVOU'
                  name='nonChristianVOU'
                  value={formData.nonChristianVOU}
                  onChange={handleInputChange}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
            </div>
          </div>

          {/* Non-Christian Open */}
          <div className='mb-3'>
            <label className='form-label'>Non-Christian (P/U) - Open</label>
            <div className='row g-3 align-items-center'>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='nonChristianOP' className='me-2'>
                  P:
                </label>
                <input
                  type='number'
                  id='nonChristianOP'
                  name='nonChristianOP'
                  value={formData.nonChristianOP}
                  onChange={handleInputChange}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='nonChristianOU' className='me-2'>
                  U:
                </label>
                <input
                  type='number'
                  id='nonChristianOU'
                  name='nonChristianOU'
                  value={formData.nonChristianOU}
                  onChange={handleInputChange}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
            </div>
          </div>

          <div className='mb-3'>
            <label className='form-label'>Christian (P/U) - Seeking</label>
            <div className='row g-3 align-items-center'>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='christianP' className='me-2'>
                  P:
                </label>
                <input
                  type='number'
                  id='christianP'
                  name='christianP'
                  value={formData.christianP}
                  onChange={handleInputChange}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='christianU' className='me-2'>
                  U:
                </label>
                <input
                  type='number'
                  id='christianU'
                  name='christianU'
                  value={formData.christianU}
                  onChange={handleInputChange}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
            </div>
          </div>

          <div className='mb-3'>
            <label className='form-label'>Home Meeting (P/U)</label>
            <div className='row g-3 align-items-center'>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='homeP' className='me-2'>
                  P:
                </label>
                <input
                  type='number'
                  id='homeP'
                  name='homeP'
                  value={formData.homeP}
                  onChange={handleInputChange}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
              <div className='col-md-6 d-flex align-items-center'>
                <label htmlFor='homeU' className='me-2'>
                  U:
                </label>
                <input
                  type='number'
                  id='homeU'
                  name='homeU'
                  value={formData.homeU}
                  onChange={handleInputChange}
                  className='form-control'
                  min='0'
                  onFocus={(e) => e.target.select()}
                />
              </div>
            </div>
          </div>

          {/* Baptism */}
          <div className='mb-3'>
            <label htmlFor='baptism' className='form-label'>
              Baptism
            </label>
            <input
              type='number'
              id='baptism'
              name='baptism'
              value={formData.baptism}
              onChange={handleInputChange}
              className='form-control'
              min='0'
              onFocus={(e) => e.target.select()}
            />
          </div>

          <div className='text-end'>
            <button
              type='submit'
              className='btn btn-outline-warning btn-sm me-3'
            >
              Kemas Kini
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={handleCancel}
            >
              Batal
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default EditStatisticTamu
