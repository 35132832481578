import React from 'react'
import Layout from '../../components/Layout/Layout'
import { Link } from 'react-router-dom'

const AdminDashboard = () => {
  return (
    <Layout title={'Admin Dashboard'}>
      <div className='container'>
        {/* First Row */}
        <div className='row'>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/book-stock'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Main Books Stock
              </Link>
            </div>
          </div>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/packing-list'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Form 1 - Team Records
              </Link>
            </div>
          </div>
        </div>

        {/* Second Row */}
        <div className='row'>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/summary-book'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Summary Book Sale
              </Link>
            </div>
          </div>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/summary-statistic'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Summary Statistics
              </Link>
            </div>
          </div>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/summary-conference'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Summary Conference
              </Link>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/group-book-sale'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Group Total Book Sale
              </Link>
            </div>
          </div>

          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/team-book-sale'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Team Book Sale Records
              </Link>
            </div>
          </div>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/team-statistic'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Team Statistics Records
              </Link>
            </div>
          </div>
        </div>

        {/* Third Row */}
        <div className='row'>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/places'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Places
              </Link>
            </div>
          </div>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/group'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Group / Team
              </Link>
            </div>
          </div>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/users'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Users
              </Link>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-12 col-md-4 mt-4 mb-2 gx-3 gy-3'>
            <div className='card'>
              <Link
                to='/admin/contact-list'
                className='btn btn-outline-success'
                style={{ fontSize: '20px' }}
              >
                Contact Name List
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AdminDashboard
