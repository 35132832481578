import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import useIsMobile from '../../../hooks/useIsMobile'
import { useAuth } from '../../../context/auth'

const AdminTeamBookSaleData = () => {
  const navigate = useNavigate()
  const { groupId } = useParams()
  const [auth] = useAuth()
  const isMobile = useIsMobile()

  const [groupedLists, setGroupedLists] = useState({})
  const [groupName, setGroupName] = useState('')
  const [loading, setLoading] = useState(true) // Add loading state

  useEffect(() => {
    const fetchList = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/admin/summary/groups/book-sale',
          {
            params: { groupId: groupId },
          }
        )

        setGroupName(data.groupName)
        groupByDate(data.lists)
      } catch (error) {
        console.error(
          'Failed to fetch lists:',
          error.response?.data || error.message
        )
      } finally {
        setLoading(false) // Set loading to false after data fetch
      }
    }

    fetchList()
  }, [auth, navigate, groupId])

  const groupByDate = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const date = new Date(item.recordDate).toISOString().split('T')[0]
      if (!acc[date]) acc[date] = []
      acc[date].push(item)
      return acc
    }, {})
    setGroupedLists(groupedData)
  }

  const truncateText = (text, wordLimit) => {
    if (!text) return ''
    const words = text.split(' ')
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...'
    }
    return text
  }

  const handleReturnHome = () => {
    navigate('/admin/team-book-sale')
  }

  return (
    <Layout title={'Team Book Sale Records'}>
      <div className='pt-3 mx-3'>
        <div
          className={`d-flex mb-3 ${
            isMobile
              ? 'flex-column'
              : 'justify-content-between align-items-center'
          }`}
        >
          <div
            className={`w-100`}
            style={{
              textAlign: 'inherit',
            }}
          >
            <h5
              className='mb-0'
              style={{
                color: '#556B2F',
              }}
            >
              {loading ? (
                <div
                  className='spinner-border spinner-border-sm text-success'
                  role='status'
                >
                  <span className='visually-hidden'>Loading...</span>
                </div>
              ) : (
                groupName
              )}
            </h5>
          </div>

          <div className='d-flex justify-content-end w-100'>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={handleReturnHome}
            >
              Team List
            </button>
          </div>
        </div>

        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>Book</th>
                <th scope='col' className='text-end'>
                  Price
                </th>
                <th scope='col' className='text-center'>
                  Qty
                </th>
                <th scope='col' className='text-end'>
                  Total
                </th>
                <th className='text-end' style={{ whiteSpace: 'nowrap' }}>
                  Kad Respon
                </th>
                <th>Remark</th>
                <th>Team</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan='8' className='text-center'>
                    <div
                      className='spinner-border spinner-border-sm text-success'
                      role='status'
                    >
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : Object.keys(groupedLists).length > 0 ? (
                Object.entries(groupedLists).map(([date, items]) => {
                  const totalQty = items.reduce(
                    (sum, item) => sum + item.qty,
                    0
                  )
                  const totalAmount = items.reduce(
                    (sum, item) => sum + item.qty * item.price,
                    0
                  )

                  return (
                    <React.Fragment key={date}>
                      <tr>
                        <td
                          colSpan='8'
                          className='bg-light fw-bold text-center'
                          style={{ color: '#0c432a' }}
                        >
                          {new Date(date).toLocaleDateString('en-GB', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })}
                        </td>
                      </tr>
                      {items.map((item) => (
                        <React.Fragment key={item._id}>
                          <tr>
                            <td>{truncateText(item.description, 8)}</td>
                            <td className='text-end'>
                              {item.price.toFixed(2)}
                            </td>
                            <td className='text-center'>{item.qty}</td>
                            <td className='text-end'>
                              {(item.price * item.qty).toFixed(2)}
                            </td>
                            <td className='text-end'>
                              {item.contactData
                                ? item.contactData.slice(-6)
                                : null}
                            </td>
                            <td>{item.remark}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                              {item.teamId ? item.teamId.teamName : 'No Team'}
                            </td>
                            <td>
                              {item.placeCat === 1
                                ? 'PB.'
                                : item.placeCat === 2
                                ? 'Tamu'
                                : 'Conf.'}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                      {/* Total Row */}
                      <tr className='fw-bold bg-light'>
                        <td>TOTAL</td>
                        <td className='text-end'></td>
                        <td className='text-center'>{totalQty}</td>
                        <td className='text-end'>{totalAmount.toFixed(2)}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </React.Fragment>
                  )
                })
              ) : (
                <tr>
                  <td colSpan='8' className='text-center'>
                    Tiada rekod
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default AdminTeamBookSaleData
