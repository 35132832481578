import React, { useState, useEffect, useRef, useCallback } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const AdminGroupSaleList = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [records, setRecords] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchData, setSearchData] = useState('')
  const [hasMore, setHasMore] = useState(true)
  const [overallTotals, setOverallTotals] = useState({
    totalAmt: 0,
    totalQty: 0,
    teamAAmt: 0,
    teamBAmt: 0,
    teamCAmt: 0,
    teamDAmt: 0,
  })

  const observer = useRef(null)

  const getAllItems = async () => {
    if (loading || !hasMore) return
    setLoading(true)
    try {
      const response = await axios.get(
        '/api/v1/admin/summary/groups/total-sale',
        {
          params: { page, limit: 30 },
        }
      )
      const newItems = response.data?.data || []
      const totals = response.data?.overallTotals || {}

      setRecords((prevItems) => [...prevItems, ...newItems])
      setOverallTotals(totals)
      setHasMore(newItems.length > 0)
    } catch (error) {
      console.error('Error fetching items:', error)
    } finally {
      setLoading(false)
    }
  }

  const searchProductResult = async () => {
    if (searchData.length < 4) return
    setLoading(true)
    try {
      const response = await axios.post('/api/v1/admin/summary/groups/search', {
        searchData,
      })
      const newItems = response.data?.data || []
      const totals = response.data?.overallTotals || {}

      setRecords(newItems)
      setOverallTotals(totals)
      setHasMore(false) // Stop infinite scroll for search
    } catch (error) {
      console.error('Error searching items:', error)
    } finally {
      setLoading(false)
    }
  }

  // Infinite scroll observer
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  useEffect(() => {
    if (searchData.length > 3) {
      searchProductResult()
    }
  }, [searchData])

  useEffect(() => {
    if (!searchData) {
      getAllItems()
    }
  }, [page, searchData])

  const handleClearSearch = () => {
    setSearchData('')
    setPage(1)
    setRecords([])
    setHasMore(true)
    getAllItems()
  }

  const handleExport = async () => {
    try {
      const response = await axios.get('/api/v1/admin/summary/groups/export', {
        params: { search: searchData },
        responseType: 'blob', // Important to handle binary data
      })

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'sales.xlsx') // Specify the file name
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } catch (error) {
      console.error('Error exporting data:', error)
    }
  }

  const truncateDescription = (description) => {
    if (!description) return ''
    const words = description.split(' ')
    return words.length > 8 ? `${words.slice(0, 8).join(' ')}...` : description
  }

  const handleReturnHome = () => {
    navigate('/admin/dashboard')
  }

  return (
    <Layout title={'Group Total Book Sale'}>
      <div
        className='searchInventory shadow-sm p-3 bg-white rounded'
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'auto',
          position: 'fixed',
          zIndex: 1000,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div className='search-bar' style={{ display: 'flex', width: '50%' }}>
            <input
              type='text'
              className='form-control'
              value={searchData}
              placeholder='Search Item Code / Book Name'
              onChange={(e) => setSearchData(e.target.value)}
            />
            <button
              type='button'
              className='btn btn-outline-secondary'
              disabled={!searchData}
              onClick={handleClearSearch}
            >
              Clear
            </button>
          </div>

          <div
            className='button-group'
            style={{
              display: 'flex',
              gap: '20px',
            }}
          >
            <button
              type='button'
              className='btn btn-primary btn-sm'
              onClick={handleExport}
            >
              Export
            </button>
            <button
              type='button'
              className='btn btn-primary btn-sm'
              onClick={() => window.scrollTo(0, 0)}
            >
              <i className='bi bi-arrow-up-circle'></i>
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={handleReturnHome}
            >
              <i className='bi bi-house-fill'></i>
            </button>
          </div>
        </div>
        <div style={{ marginTop: '8px', fontWeight: 'bold' }}>
          Total: RM{' '}
          {new Intl.NumberFormat('en-MY', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(overallTotals.totalAmt || 0)}{' '}
          ({overallTotals.totalQty}) Team A: RM{' '}
          {new Intl.NumberFormat('en-MY', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(overallTotals.teamAAmt || 0)}{' '}
          ({overallTotals.teamAQty}) Team B: RM{' '}
          {new Intl.NumberFormat('en-MY', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(overallTotals.teamBAmt || 0)}{' '}
          ({overallTotals.teamBQty}) Team C: RM{' '}
          {new Intl.NumberFormat('en-MY', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(overallTotals.teamCAmt || 0)}{' '}
          ({overallTotals.teamCQty}) Team D: RM{' '}
          {new Intl.NumberFormat('en-MY', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(overallTotals.teamDAmt || 0)}{' '}
          ({overallTotals.teamDQty})
        </div>
      </div>

      <div>
        <div className='table-responsive'>
          <table className='table actable' style={{ marginTop: '100px' }}>
            <thead>
              <tr>
                <th>Book</th>
                <th className='text-end'>Total Amt (Qty)</th>
                <th className='text-end'>Team A</th>
                <th className='text-end'>Team B</th>
                <th className='text-end'>Team C</th>
                <th className='text-end'>Team D</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record, index) => (
                <tr
                  key={record.itemCode}
                  ref={index === records.length - 1 ? lastElementRef : null}
                >
                  <td>
                    <span className='me-3'>{record.itemCode}</span>
                    {truncateDescription(record.description)}
                  </td>
                  <td className='text-end'>
                    {record.totalAmt ? record.totalAmt.toFixed(2) : '0.00'} (
                    {record.totalQty})
                  </td>
                  <td className='text-end'>
                    {record.teamAAmt ? record.teamAAmt.toFixed(2) : '0.00'} (
                    {record.teamAQty})
                  </td>
                  <td className='text-end'>
                    {record.teamBAmt ? record.teamBAmt.toFixed(2) : '0.00'} (
                    {record.teamBQty})
                  </td>
                  <td className='text-end'>
                    {record.teamCAmt ? record.teamCAmt.toFixed(2) : '0.00'} (
                    {record.teamCQty})
                  </td>
                  <td className='text-end'>
                    {record.teamDAmt ? record.teamDAmt.toFixed(2) : '0.00'} (
                    {record.teamDQty})
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='text-center'>
          {loading && <p>Loading items...</p>}
          {!hasMore && <p>No more records to load.</p>}
        </div>
      </div>
    </Layout>
  )
}

export default AdminGroupSaleList
