import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import useIsMobile from '../../../hooks/useIsMobile'
import { useAuth } from '../../../context/auth'

const MainSaleRecordList = () => {
  const navigate = useNavigate()
  const [auth] = useAuth()
  const userId = auth?.user?.id
  const groupId = auth?.user?.group

  const isMobile = useIsMobile()
  const [groupedLists, setGroupedLists] = useState({})

  useEffect(() => {
    const fetchList = async () => {
      try {
        if (!groupId) {
          console.error('No groupId found. Please log in.')
          navigate('/login')
          return
        }

        const { data } = await axios.get('/api/v1/main/sale-record/lists', {
          params: { groupId: groupId },
        })

        groupByDate(data)
      } catch (error) {
        console.error(
          'Failed to fetch lists:',
          error.response?.data || error.message
        )
      }
    }

    fetchList()
  }, [auth, navigate])

  const groupByDate = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const date = new Date(item.recordDate).toISOString().split('T')[0]
      if (!acc[date]) acc[date] = []
      acc[date].push(item)
      return acc
    }, {})
    setGroupedLists(groupedData)
  }

  const truncateText = (text, wordLimit) => {
    if (!text) return ''
    const words = text.split(' ')
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...'
    }
    return text
  }

  const handleReturnHome = () => {
    navigate('/main/dashboard')
  }

  return (
    <Layout title={'Rekod Jualan Buku'}>
      <div className='pt-3 mx-2'>
        {!isMobile && (
          <>
            <div
              className={`d-flex mb-3 ${
                isMobile
                  ? 'flex-column'
                  : 'justify-content-between align-items-center'
              }`}
            >
              <div
                className={`w-100`}
                style={{
                  textAlign: 'inherit',
                }}
              >
                <h5
                  className='mb-0'
                  style={{
                    color: '#556B2F',
                  }}
                >
                  Team Book Sale Record
                </h5>
              </div>

              <div className='d-flex justify-content-end w-100'>
                <button
                  type='button'
                  className='btn btn-outline-secondary btn-sm'
                  onClick={handleReturnHome}
                >
                  <i className='bi bi-house-fill'></i>
                </button>
              </div>
            </div>
          </>
        )}

        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                {/* Show Book column always */}
                <th scope='col'>Book</th>

                {/* Only show these columns on Desktop */}
                {!isMobile && (
                  <>
                    <th scope='col' className='text-end'>
                      Price
                    </th>
                    <th scope='col' className='text-center'>
                      Qty
                    </th>
                    <th scope='col' className='text-end'>
                      Total
                    </th>
                    <th className='text-end' style={{ whiteSpace: 'nowrap' }}>
                      Kad Respon
                    </th>
                    <th>Remark</th>
                    <th>Team</th>
                    <th></th>
                  </>
                )}

                {isMobile && (
                  <>
                    <th className='text-end'>Total</th>
                  </>
                )}
              </tr>
            </thead>

            <tbody>
              {Object.keys(groupedLists).length > 0 ? (
                Object.entries(groupedLists).map(([date, items]) => {
                  const totalQty = items.reduce(
                    (sum, item) => sum + item.qty,
                    0
                  )
                  const totalAmount = items.reduce(
                    (sum, item) => sum + item.qty * item.price,
                    0
                  )

                  return (
                    <React.Fragment key={date}>
                      <tr>
                        <td
                          colSpan={isMobile ? '2' : '8'}
                          className='bg-light fw-bold text-center'
                          style={{ color: '#0c432a' }}
                        >
                          {new Date(date).toLocaleDateString('en-GB', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })}
                        </td>
                      </tr>

                      {/* For Desktop */}
                      {!isMobile &&
                        items.map((item) => (
                          <React.Fragment key={item._id}>
                            <tr>
                              <td>{truncateText(item.description, 8)}</td>
                              <td className='text-end'>
                                {item.price.toFixed(2)}
                              </td>
                              <td className='text-center'>{item.qty}</td>
                              <td className='text-end'>
                                {(item.price * item.qty).toFixed(2)}
                              </td>
                              <td className='text-end'>
                                {item.contactData
                                  ? item.contactData.slice(-6)
                                  : null}
                              </td>
                              <td>{item.remark}</td>
                              <td style={{ whiteSpace: 'nowrap' }}>
                                {item.teamId ? item.teamId.teamName : 'No Team'}
                              </td>
                              <td>
                                {item.placeCat === 1
                                  ? 'PB.'
                                  : item.placeCat === 2
                                  ? 'Tamu'
                                  : 'Conf.'}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}

                      {/* For Mobile */}
                      {isMobile &&
                        items.map((item) => (
                          <React.Fragment key={item._id}>
                            <tr>
                              <td>
                                {truncateText(item.description, 8)}
                                <div>
                                  RM {item.price.toFixed(2)} x {item.qty}
                                </div>
                              </td>
                              <td className='text-end'>
                                RM {(item.price * item.qty).toFixed(2)}
                                <div style={{ whiteSpace: 'nowrap' }}>
                                  {item.teamId ? item.teamId.teamName : ''}
                                </div>
                              </td>
                            </tr>

                            {/* Mobile Second Row (Qty, Total, Kad Respon, Remark) */}
                            <tr
                              style={{
                                borderBottom: item.remark
                                  ? 'none'
                                  : '1px solid #635a5a',
                              }}
                            >
                              <td>
                                Kad Respon:{' '}
                                {item.contactData
                                  ? item.contactData.slice(-6)
                                  : '-'}
                              </td>
                              <td className='text-end'>
                                {item.placeCat === 1
                                  ? 'PB.'
                                  : item.placeCat === 2
                                  ? 'Tamu'
                                  : 'Conf.'}
                              </td>
                            </tr>
                            {item.remark && (
                              <tr>
                                <td
                                  colSpan={2}
                                  className='py-1'
                                  style={{ borderBottom: '1px solid #635a5a' }}
                                >
                                  Catatan: {item.remark}
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}

                      {/* Total Row */}
                      {!isMobile ? (
                        <tr className='fw-bold bg-light'>
                          <td>TOTAL</td>
                          <td className='text-end'></td>
                          <td className='text-center'>{totalQty}</td>
                          <td className='text-end'>{totalAmount.toFixed(2)}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      ) : (
                        <tr className='fw-bold bg-light'>
                          <td>TOTAL</td>
                          <td
                            className='text-end'
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            RM {totalAmount.toFixed(2)} ({totalQty})
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={isMobile ? '2' : '8'} className='text-center'>
                    Tiada rekod
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default MainSaleRecordList
