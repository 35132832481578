import React, { useState, useEffect } from 'react'
import Layout from '../../../components/Layout/Layout'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import CreateNewPlace from './CreateNewPlace'
import axios from 'axios'
import { toast } from 'react-toastify'

const AdminPlaceList = () => {
  const navigate = useNavigate()

  const [showCreateModal, setShowCreateModal] = useState(false)
  const [places, setPlaces] = useState([])
  const [loading, setLoading] = useState(true)
  const [editablePlace, setEditablePlace] = useState(null)
  const [editedPlaceName, setEditedPlaceName] = useState('')

  const fetchPlaces = async () => {
    try {
      const { data } = await axios.get('/api/v1/admin/place/list')
      setPlaces(data)
      setLoading(false)
    } catch (error) {
      console.error('Failed to fetch places:', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPlaces()
  }, [])

  const handleShowCreateModal = () => {
    setShowCreateModal(true)
  }

  const handleCloseCreateModal = () => {
    setShowCreateModal(false)
  }

  const handleReturnHome = () => {
    navigate('/admin/dashboard')
  }

  const handleDoubleClick = (place) => {
    setEditablePlace(place._id)
    setEditedPlaceName(place.placeName)
  }

  const handlePlaceNameChange = (e) => {
    setEditedPlaceName(e.target.value)
  }

  const savePlaceName = async (placeId) => {
    if (
      !editedPlaceName ||
      places.some((category) =>
        category.places.some(
          (place) =>
            place._id === placeId && place.placeName === editedPlaceName
        )
      )
    ) {
      setEditablePlace(null)
      return
    }

    try {
      await axios.put(`/api/v1/admin/place/edit/${placeId}`, {
        placeName: editedPlaceName,
      })
      setPlaces((prevPlaces) =>
        prevPlaces.map((category) => ({
          ...category,
          places: category.places.map((place) =>
            place._id === placeId
              ? { ...place, placeName: editedPlaceName }
              : place
          ),
        }))
      )

      setEditablePlace(null)
    } catch (error) {
      toast.error('Failed to update place')
    }
  }

  const handleStatusChange = async (place) => {
    const confirm = window.confirm('Confirm change status?')
    if (!confirm) return

    const updatedStatus = place.status === 1 ? 2 : 1

    try {
      await axios.put(`/api/v1/admin/place/edit/${place._id}`, {
        status: updatedStatus,
      })
      setPlaces((prevPlaces) =>
        prevPlaces.map((category) => ({
          ...category,
          places: category.places.map((p) =>
            p._id === place._id ? { ...p, status: updatedStatus } : p
          ),
        }))
      )
    } catch (error) {
      toast.error('Failed to update status')
    }
  }

  return (
    <Layout title={'Place'}>
      <div className='pt-3 mx-3'>
        <div className='text-end mb-3'>
          <button
            type='button'
            className='btn btn-outline-success btn-sm me-4'
            onClick={handleShowCreateModal}
          >
            <i className='bi bi-plus-circle'></i> Place
          </button>

          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={handleReturnHome}
          >
            <i className='bi bi-house-fill'></i>
          </button>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>Group</th>
                <th scope='col'>Place</th>
                <th scope='col'>Status</th>
              </tr>
            </thead>
            <tbody>
              {places.map((category) => (
                <React.Fragment key={category._id}>
                  <tr>
                    <td
                      colSpan={4}
                      className='text-center'
                      style={{
                        backgroundColor: '#ecf2e2',
                        color: '#556B2F',
                        fontWeight: 'bold',
                      }}
                    >
                      {category._id === 1
                        ? 'Book Propagation Places'
                        : category._id === 2
                        ? 'Tamu Places'
                        : ''}
                    </td>
                  </tr>
                  {category.places.map((place) => (
                    <tr key={place._id}>
                      <td>{place.groupName}</td>
                      <td
                        onDoubleClick={() => handleDoubleClick(place)}
                        style={{ cursor: 'pointer' }}
                      >
                        {editablePlace === place._id ? (
                          <input
                            type='text'
                            value={editedPlaceName}
                            onChange={handlePlaceNameChange}
                            onBlur={() => savePlaceName(place._id)}
                            onFocus={(e) => e.target.select()}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault()
                                savePlaceName(place._id)
                              }
                            }}
                            autoFocus
                          />
                        ) : (
                          place.placeName
                        )}
                      </td>
                      <td
                        style={{
                          color: place.status === 1 ? 'green' : 'grey',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleStatusChange(place)}
                      >
                        {place.status === 1 ? 'Active' : 'Inactive'}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <Modal show={showCreateModal} onHide={handleCloseCreateModal}>
        <CreateNewPlace
          onClose={handleCloseCreateModal}
          refreshPlaces={fetchPlaces}
        />
      </Modal>
    </Layout>
  )
}

export default AdminPlaceList
