import React, { useState, useEffect, useRef, useCallback } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import AdminAddBook from './AddBook'
import AdminBookCategory from './BookCategory'
import AdminEditBook from './EditBook'
import { useNavigate } from 'react-router-dom'

const AdminBookStockList = () => {
  const navigate = useNavigate()

  const [items, setItems] = useState([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [searchData, setSearchData] = useState('')
  const [hasMore, setHasMore] = useState(true)

  const [editableRow, setEditableRow] = useState(null)
  const [editedPrice, setEditedPrice] = useState('')

  const [showAddBookModal, setShowAddBookModal] = useState(false)
  const [showEditBookModal, setShowEditBookModal] = useState(false)
  const [editBookData, setEditBookData] = useState(null)
  const [showCategoryModal, setShowCategoryModal] = useState(false)

  const observer = useRef(null)

  const getAllItems = async () => {
    if (loading || !hasMore) return
    setLoading(true)
    try {
      const response = await axios.get('/api/v1/admin/bookstock/list', {
        params: { page, limit: 30 },
      })
      const newItems = response.data?.items || []
      setItems((prevItems) => [...prevItems, ...newItems])
      setHasMore(newItems.length > 0)
    } catch (error) {
      console.error('Error fetching items:', error)
    } finally {
      setLoading(false)
    }
  }

  const searchProductResult = async () => {
    if (searchData.length < 4) return
    setLoading(true)
    try {
      const response = await axios.post('/api/v1/admin/bookstock/search', {
        searchData,
      })
      setItems(response.data?.items || [])
      setHasMore(false)
    } catch (error) {
      console.error('Error searching items:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (searchData.length > 3) searchProductResult()
  }, [searchData])

  useEffect(() => {
    if (!searchData) {
      getAllItems()
    }
  }, [page, searchData])

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  const handleClearSearch = () => {
    setSearchData('')
    setPage(1)
    setItems([])
    setHasMore(true)
    getAllItems()
  }

  const handleEditPrice = (id, price) => {
    setEditableRow(id)
    setEditedPrice(price)
  }

  const handleSavePrice = async (id) => {
    const item = items.find((item) => item._id === id)
    if (!item || item.price === parseFloat(editedPrice)) {
      setEditableRow(null)
      setEditedPrice('')
      return
    }

    try {
      await axios.put(`/api/v1/admin/bookstock/update-price`, {
        id,
        price: editedPrice,
      })
      setItems((prevItems) =>
        prevItems.map((item) =>
          item._id === id ? { ...item, price: parseFloat(editedPrice) } : item
        )
      )
      setEditableRow(null)
      setEditedPrice('')
    } catch (error) {
      console.error('Error updating price:', error)
    }
  }

  const handleKeyDown = (e, id) => {
    if (e.key === 'Enter') {
      handleSavePrice(id)
    } else if (e.key === 'Escape') {
      setEditableRow(null)
      setEditedPrice('')
    }
  }

  const handleAddBook = () => {
    setShowAddBookModal(true)
  }

  const handleCloseAddBookModal = () => {
    setShowAddBookModal(false)
  }

  const handleCategory = () => {
    setShowCategoryModal(true)
  }

  const handleCloseCategoryModal = () => {
    setShowCategoryModal(false)
  }

  const handleEditBook = (bookId) => {
    setEditBookData(bookId)
    setShowEditBookModal(true)
  }

  const handleCloseEditBookModal = () => {
    setShowEditBookModal(false)
    setEditBookData(null)
  }

  const handleUpdateBook = (updatedBook) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item._id === updatedBook._id ? { ...item, ...updatedBook } : item
      )
    )
  }

  const handleReturnHome = () => {
    navigate('/admin/dashboard')
  }

  return (
    <Layout title={'Book Stock Record'}>
      <div
        className='searchInventory shadow-sm p-3 bg-white rounded'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '65px',
          position: 'fixed',
          zIndex: 1000,
        }}
      >
        <div className='search-bar' style={{ display: 'flex', width: '50%' }}>
          <input
            type='text'
            className='form-control'
            value={searchData}
            placeholder='Search Item Code / Book Name / Barcode'
            onChange={(e) => setSearchData(e.target.value)}
          />
          <button
            type='button'
            className='btn btn-outline-secondary'
            disabled={!searchData}
            onClick={handleClearSearch}
          >
            Clear
          </button>
        </div>

        <div
          className='button-group'
          style={{
            display: 'flex',
            gap: '20px',
          }}
        >
          <button
            type='button'
            className='btn btn-outline-success btn-sm'
            onClick={handleAddBook}
          >
            <i className='bi bi-plus-circle'></i> Book
          </button>
          <button
            type='button'
            className='btn btn-outline-info btn-sm'
            onClick={handleCategory}
          >
            Category
          </button>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            onClick={() => window.scrollTo(0, 0)}
          >
            <i className='bi bi-arrow-up-circle'></i>
          </button>
          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={handleReturnHome}
          >
            <i className='bi bi-house-fill'></i>
          </button>
        </div>
      </div>
      <div>
        <div className='table-responsive'>
          <table className='table actable' style={{ marginTop: '80px' }}>
            <thead>
              <tr>
                <th>Item Code</th>
                <th>Book</th>
                <th className='text-end'>Price</th>
                <th>Category</th>
                <th>Barcode</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr
                  key={index}
                  ref={
                    index === items.length - 1 && hasMore && !searchData
                      ? lastElementRef
                      : null
                  }
                >
                  <td>{item.itemCode}</td>
                  <td>{item.description}</td>
                  <td
                    className='text-end'
                    onDoubleClick={() => handleEditPrice(item._id, item.price)}
                  >
                    {editableRow === item._id ? (
                      <input
                        type='number'
                        value={editedPrice}
                        onChange={(e) => setEditedPrice(e.target.value)}
                        onBlur={() => handleSavePrice(item._id)}
                        onKeyDown={(e) => handleKeyDown(e, item._id)}
                        autoFocus
                      />
                    ) : (
                      item.price?.toFixed(2)
                    )}
                  </td>
                  <td>{item.itemType}</td>
                  <td>{item.barCode}</td>
                  <td className='text-end'>
                    <button
                      type='button'
                      className='btn btn-outline-primary btn-sm'
                      onClick={() => handleEditBook(item._id)}
                    >
                      <i className='bi bi-pencil-square'></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='text-center'>{loading && <p>Loading items...</p>}</div>
      </div>

      <AdminAddBook show={showAddBookModal} onClose={handleCloseAddBookModal} />
      <AdminEditBook
        show={showEditBookModal}
        onClose={handleCloseEditBookModal}
        bookId={editBookData}
        onUpdateBook={handleUpdateBook}
      />
      <AdminBookCategory
        show={showCategoryModal}
        onClose={handleCloseCategoryModal}
      />
    </Layout>
  )
}

export default AdminBookStockList
