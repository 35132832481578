import React, { useState, useEffect } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { toast } from 'react-toastify'

const AdminSummaryBook = () => {
  const [totalQty, setTotalQty] = useState(0)
  const [subsidizedCopies, setSubsidizedCopies] = useState(0)
  const [totalRcVBibles, setTotalRcVBibles] = useState(0)
  const [totalRcVText, setTotalRcVText] = useState(0)
  const [totalRcVWasiatBaru, setTotalRcVWasiatBaru] = useState(0)
  const [groupSales, setGroupSales] = useState([])
  const [accTotalQty, setAccTotalQty] = useState(0)
  const [accSubsidizedCopies, setAccSubsidizedCopies] = useState(0)
  const [accRcVBibles, setAccRcVBibles] = useState(0)
  const [accRcVText, setAccRcVText] = useState(0)
  const [accRcVWasiatBaru, setAccRcVWasiatBaru] = useState(0)
  const [accGroupSales, setAccGroupSales] = useState([])
  const [loading, setLoading] = useState(false)

  const todayDate = new Date().toLocaleDateString('en-GB')
  const othersQty =
    totalQty -
    subsidizedCopies -
    totalRcVBibles -
    totalRcVText -
    totalRcVWasiatBaru

  const accOthersQty =
    accTotalQty -
    accSubsidizedCopies -
    accRcVBibles -
    accRcVText -
    accRcVWasiatBaru

  useEffect(() => {
    const fetchBookSales = async () => {
      try {
        setLoading(true)
        const response = await axios.get(
          '/api/v1/admin/summary/today-book-sales'
        )
        if (response.data.success) {
          setTotalQty(response.data.data.totalQty)
          setSubsidizedCopies(response.data.data.subsidizedCopies)
          setTotalRcVBibles(response.data.data.totalRcVBibles)
          setTotalRcVText(response.data.data.totalRcVText)
          setTotalRcVWasiatBaru(response.data.data.totalRcVWasiatBaru)
          setGroupSales(response.data.data.groupSales)
        } else {
          toast.error(response.data.message || 'Failed to fetch book sales.')
        }
      } catch (error) {
        console.error('Error fetching book sales:', error.message)
        toast.error('Error fetching book sales.')
      } finally {
        setLoading(false)
      }
    }

    const fetchAccumulatedSales = async () => {
      try {
        const response = await axios.get(
          '/api/v1/admin/summary/accumulate-book-sales'
        )
        if (response.data.success) {
          const data = response.data.data
          setAccTotalQty(data.totalQty)
          setAccSubsidizedCopies(data.subsidizedCopies)
          setAccRcVBibles(data.totalRcVBibles)
          setAccRcVText(data.totalRcVText)
          setAccRcVWasiatBaru(data.totalRcVWasiatBaru)
          setAccGroupSales(data.groupSales)
        } else {
          toast.error(
            response.data.message || 'Failed to fetch accumulated book sales.'
          )
        }
      } catch (error) {
        console.error('Error fetching accumulated book sales:', error.message)
        toast.error('Error fetching accumulated book sales.')
      }
    }

    fetchBookSales()
    fetchAccumulatedSales()
  }, [])

  return (
    <Layout title={'Summary Book Sale'}>
      <div className='pt-3 mx-3'>
        <h5>Summary Book Sale</h5>
        <div className='fw-bold mb-2'>Today's Date: {todayDate}</div>

        {loading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <div>
              RcV-Holy Bible (VP), OT & NT with notes HB: {totalRcVBibles}
            </div>
            <div>RcV-Holy Bible (VP), text only, Black, HB: {totalRcVText}</div>
            <div>RcV-Holy Bible NT Wasiat Baru: {totalRcVWasiatBaru}</div>
            <div>Subsidized copies: {subsidizedCopies}</div>
            <div>Others: {othersQty}</div>
            <div>Total: {totalQty}</div>
            <div>
              {groupSales.map((group, index) => (
                <span key={group._id}>
                  {group.groupName}: {group.totalQty}
                  {index < groupSales.length - 1 && ', '}
                </span>
              ))}
            </div>
          </div>
        )}

        <div className='fw-bold mt-3 mb-2'>
          Statistics from 27/12/2024 to {todayDate}
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <div>
              RcV-Holy Bible (VP), OT & NT with notes HB: {accRcVBibles}
            </div>
            <div>RcV-Holy Bible (VP), text only, Black, HB: {accRcVText}</div>
            <div>RcV-Holy Bible NT Wasiat Baru: {accRcVWasiatBaru}</div>
            <div>Subsidized copies: {accSubsidizedCopies}</div>
            <div>Others: {accOthersQty}</div>
            <div>Total: {accTotalQty}</div>
            <div>
              {accGroupSales.map((group, index) => (
                <span key={group._id}>
                  {group.groupName}: {group.totalQty}
                  {index < accGroupSales.length - 1 && ', '}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default AdminSummaryBook
