import React, { useCallback, useEffect, useRef, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../context/auth'
import { Modal, Button } from 'antd'
import QtyLog from './QtyLog'
import ItemQty from './ItemQty'
import MainPackingListTable from './PackingListTable'
import { saveAs } from 'file-saver'
import useIsMobile from './../../../hooks/useIsMobile'
import SaleLog from './SaleLog'

const MainPackingListDetails = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const [searchData, setSearchData] = useState('')
  const [packingDataList, setPackingDataList] = useState([])
  const [editMode, setEditMode] = useState(null)
  const [editField, setEditField] = useState(null)
  const [optionEnter, setOptionEnter] = useState('Take')
  const [filterQuery, setFilterQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [visibleLogModal, setVisibleLogModal] = useState(false)
  const [currentItemId, setCurrentItemId] = useState(null)
  const [currentItemDetails, setCurrentItemDetails] = useState({})
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const [visibleQtyModal, setVisibleQtyModal] = useState(false)
  const [originalValues, setOriginalValues] = useState({})
  const barcodeInputRef = useRef(null)
  const [focusBarcodeInput, setFocusBarcodeInput] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [totalPackingLists, setTotalPackingLists] = useState(0)
  const [isUpdatingSale, setIsUpdatingSale] = useState(false)
  const [isSaleLogModalVisible, setSaleLogModalVisible] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)

  const [auth] = useAuth()
  const userId = auth?.user?.id
  const groupId = auth?.user?.group
  const [groupName, setGroupName] = useState('')

  const [quantities, setQuantities] = useState({
    totalItems: 0,
    totalTakeQty: 0,
    totalReturnQty: 0,
    totalSaleQty: 0,
  })

  useEffect(() => {
    const shouldFetch = filterQuery.length > 3 || filterQuery === ''
    if (shouldFetch) {
      fetchData(page)
    }
  }, [page, filterQuery])

  useEffect(() => {
    if (filterQuery.length > 3 || filterQuery === '') {
      setPage(1)
    }
  }, [filterQuery])

  useEffect(() => {
    const fetchGroupName = async () => {
      try {
        const response = await axios.get(
          `/api/v1/main/book-record/group/${groupId}`
        )
        setGroupName(response.data.name)
      } catch (error) {
        console.error('Failed to fetch group name:', error)
      }
    }

    if (groupId) {
      fetchGroupName()
    }
  }, [groupId])

  useEffect(() => {
    if (focusBarcodeInput) {
      barcodeInputRef.current?.focus()
      setFocusBarcodeInput(false)
    } else if (optionEnter) {
      barcodeInputRef.current?.focus()
    }
  }, [focusBarcodeInput, optionEnter])

  useEffect(() => {
    if (visibleQtyModal) {
      const fetchQtyData = async () => {
        try {
          const response = await axios.get(
            `/api/v1/main/book-record/quantities/${groupId}`
          )
          setQuantities(response.data)
        } catch (error) {
          console.error('Error fetching quantities:', error)
        }
      }
      fetchQtyData()
    }
  }, [visibleQtyModal])

  const fetchData = async (newPage = 1, showToast = false) => {
    setIsLoading(true)
    try {
      const response = await axios.get('/api/v1/main/book-record/details', {
        params: {
          groupId,
          userId,
          page: newPage,
          limit: 30,
          search: filterQuery,
        },
      })

      if (newPage === 1) {
        setPackingDataList(response.data.data)
      } else {
        setPackingDataList((prev) => [...prev, ...response.data.data])
      }
      setTotalPackingLists(response.data.total)
      setHasMore(response.data.page < response.data.totalPages)
      if (showToast) {
        toast.success('Book Records are Up to Date')
      }
    } catch (error) {
      console.error('Failed to fetch packing list data:', error)
      setPackingDataList([])
    } finally {
      setIsLoading(false)
    }
  }

  const observer = useRef()
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, hasMore]
  )

  const toggleEdit = (itemId, field, currentValue) => {
    if (editMode === itemId && editField === field) {
      setEditMode(null)
      setEditField(null)
      setPackingDataList((currentList) =>
        currentList.map((item) =>
          item._id === itemId
            ? {
                ...item,
                [field]: originalValues[`${itemId}-${field}`] || item[field],
              }
            : item
        )
      )
    } else {
      setEditMode(itemId)
      setEditField(field)
      setOriginalValues((prev) => ({
        ...prev,
        [`${itemId}-${field}`]: currentValue,
      }))
    }
  }

  const handleQtyChange = (itemId, value, type) => {
    setPackingDataList((currentList) =>
      currentList.map((item) =>
        item._id === itemId ? { ...item, [type]: value } : item
      )
    )
  }

  const saveQty = async (itemId, type) => {
    const item = packingDataList.find((item) => item._id === itemId)
    if (!item) return
    const originalValue = Number(
      String(originalValues[`${itemId}-${type}`]).trim()
    )
    const updatedValue = Number(String(item[type]).trim())
    if (originalValue === updatedValue) {
      setEditMode(null)
      setEditField(null)
      setFocusBarcodeInput(true)
      return
    }
    try {
      await axios.patch(`/api/v1/main/book-record/update-item/${itemId}`, {
        [type]: updatedValue,
        userId,
      })

      const userCode = auth?.user?.code
      const updatedProduct = {
        ...item,
        checkQty: updatedValue,
        lastUptByCode: userCode,
        updatedAt: new Date(),
      }

      setPackingDataList((prevItems) => [
        updatedProduct,
        ...prevItems.filter((item) => item._id !== itemId),
      ])

      setEditMode(null)
      setEditField(null)
      setFocusBarcodeInput(true)
    } catch (error) {
      console.error(`Failed to update ${type}:`, error)
      toast.error(`Failed to update ${type}.`)
    }
  }

  const handleQtyKeyPress = (event, itemId, type) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      saveQty(itemId, type)
    }
  }

  const clearInput = () => {
    setSearchData('')
    setFocusBarcodeInput(true)
  }

  const handleFilterChange = (value) => {
    setFilterQuery(value)
  }

  const closeModal = (e) => {
    e.preventDefault()
    setVisibleLogModal(false)
  }

  const openLogModal = (itemId) => {
    const item = packingDataList.find((item) => item._id === itemId)
    if (item) {
      setCurrentItemId(itemId)
      setCurrentItemDetails({
        itemCode: item.itemCode,
        description: item.description,
      })
      setVisibleLogModal(true)
    }
  }

  const exportFile = async () => {
    try {
      const response = await axios.get(
        `/api/v1/main/book-record/export/${groupId}`,
        { responseType: 'blob' }
      )

      const contentDisposition = response.headers['content-disposition']
      let filename = 'form_1.xlsx'
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/)
        if (filenameMatch.length === 2) {
          filename = filenameMatch[1]
        }
      }

      saveAs(response.data, filename)
    } catch (error) {
      console.error('Failed to download the file:', error)
      toast.error('Failed to download the file.')
    }
  }

  const updateSaleQuantities = async () => {
    setIsUpdatingSale(true)
    try {
      const response = await axios.post(
        '/api/v1/main/book-record/update-sale',
        {
          groupId,
        }
      )

      if (response.data.success) {
        fetchData(1, true)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      console.error('Failed to update sale quantities:', error)
      toast.error('Failed to update sale quantities')
    } finally {
      setIsUpdatingSale(false)
    }
  }

  const handleOpenSaleLog = (item) => {
    setCurrentItem(item)
    setSaleLogModalVisible(true)
  }

  const handleCloseSaleLog = () => {
    setCurrentItem(null)
    setSaleLogModalVisible(false)
  }

  const handleReturnHome = () => {
    navigate('/main/dashboard')
  }

  return (
    <Layout title={'Packing List'}>
      <div
        className='shadow-sm p-3 bg-white rounded'
        style={{ width: '100%', position: 'fixed', height: '110px' }}
      >
        {!isMobile && (
          <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <div className='fw-bold'>Form 1 - Book Record [{groupName}]</div>
              <div className='d-flex justify-content-end'>
                <button
                  type='button'
                  className='btn btn-outline-secondary btn-sm'
                  onClick={handleReturnHome}
                >
                  <i className='bi bi-house-fill'></i>
                </button>
              </div>
            </div>
            <div
              className='col-auto'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div className='d-flex'>
                <input
                  type='text'
                  className='form-control py-1'
                  style={{ width: '400px' }}
                  placeholder='Enter item code or book title to search'
                  onChange={(e) => handleFilterChange(e.target.value)}
                  value={filterQuery}
                />
                <button
                  className='btn btn-outline-secondary btn-sm'
                  onClick={() => setFilterQuery('')}
                >
                  Clear
                </button>
              </div>

              <div className='d-flex ms-auto'>
                <button
                  type='button'
                  className='btn btn-outline-primary btn-sm me-3'
                  onClick={() => exportFile()}
                >
                  Export List
                </button>
                <button
                  type='button'
                  className='btn btn-outline-warning btn-sm me-3'
                  onClick={updateSaleQuantities}
                  disabled={isUpdatingSale}
                >
                  Update Sale
                  {isUpdatingSale && (
                    <span
                      className='ms-2 spinner-border spinner-border-sm text-warning'
                      role='status'
                    ></span>
                  )}
                </button>
                <button
                  type='button'
                  className='btn btn-outline-info btn-sm me-3'
                  onClick={() => {
                    setVisibleQtyModal(true)
                  }}
                >
                  Qty Details
                </button>
                <button
                  type='button'
                  className='btn btn-outline-info btn-sm'
                  onClick={() => fetchData(1, true)}
                >
                  <i className='bi bi-arrow-clockwise'></i>
                </button>
              </div>
            </div>
          </>
        )}
        {isMobile && (
          <>
            <div className='d-flex justify-content-end mb-3'>
              <button
                type='button'
                className='btn btn-outline-primary btn-sm me-2'
                onClick={() => exportFile()}
              >
                Export
              </button>
              <button
                type='button'
                className='btn btn-outline-warning btn-sm me-2'
                onClick={updateSaleQuantities}
                disabled={isUpdatingSale}
              >
                Update Sale
                {isUpdatingSale && (
                  <span
                    className='ms-2 spinner-border spinner-border-sm text-warning'
                    role='status'
                  ></span>
                )}
              </button>
              <button
                type='button'
                className='btn btn-outline-info btn-sm me-2'
                onClick={() => setVisibleQtyModal(true)}
              >
                Qty Details
              </button>
              <button
                type='button'
                className='btn btn-outline-info btn-sm'
                onClick={() => fetchData(1, true)}
              >
                <i className='bi bi-arrow-clockwise'></i>
              </button>
            </div>

            <div className='d-flex'>
              <input
                type='text'
                className='form-control py-1'
                style={{ width: '100%' }}
                placeholder='Enter item code or book title to search'
                onChange={(e) => handleFilterChange(e.target.value)}
                value={filterQuery}
              />
              <button
                className='btn btn-outline-secondary btn-sm'
                onClick={() => setFilterQuery('')}
              >
                Clear
              </button>
            </div>
          </>
        )}
      </div>

      <div>
        <div className='table-responsive'>
          <MainPackingListTable
            packingDataList={packingDataList}
            lastElementRef={lastElementRef}
            editMode={editMode}
            editField={editField}
            handleQtyChange={handleQtyChange}
            saveQty={saveQty}
            handleQtyKeyPress={handleQtyKeyPress}
            toggleEdit={toggleEdit}
            openLogModal={openLogModal}
            isMobile={isMobile}
            handleOpenSaleLog={handleOpenSaleLog}
          />
        </div>
      </div>

      <Modal
        closable={false}
        onCancel={() => setVisibleLogModal(false)}
        open={visibleLogModal}
        width={isMobile ? '100%' : '70%'}
        footer={[
          <Button key='cancel' onClick={() => setVisibleLogModal(false)}>
            Close
          </Button>,
        ]}
      >
        <QtyLog
          closeLogModal={closeModal}
          itemId={currentItemId}
          itemDetails={currentItemDetails}
          lastUpdate={lastUpdate}
        />
      </Modal>

      <Modal
        closable={false}
        onCancel={() => setVisibleQtyModal(false)}
        open={visibleQtyModal}
        footer={[
          <Button key='cancel' onClick={() => setVisibleQtyModal(false)}>
            Close
          </Button>,
        ]}
      >
        <ItemQty
          totalItems={quantities.totalItems}
          totalTakeQty={quantities.totalTakeQty}
          totalReturnQty={quantities.totalReturnQty}
          totalSaleQty={quantities.totalSaleQty}
        />
      </Modal>

      <Modal
        visible={isSaleLogModalVisible}
        onCancel={handleCloseSaleLog}
        footer={[
          <Button key='cancel' onClick={handleCloseSaleLog}>
            Close
          </Button>,
        ]}
        width={isMobile ? '100%' : '60%'}
      >
        {currentItem && (
          <SaleLog
            itemId={currentItem._id}
            itemDetails={currentItem}
            lastUpdate={new Date()}
          />
        )}
      </Modal>
    </Layout>
  )
}

export default MainPackingListDetails
