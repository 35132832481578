import React, { useState, useEffect } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { toast } from 'react-toastify'

const AdminSummaryStatistic = () => {
  const [summary, setSummary] = useState(null)
  const [baptismsByGroup, setBaptismsByGroup] = useState([])
  const [accumulatedSummary, setAccumulatedSummary] = useState(null)
  const [accumulatedBaptismsByGroup, setAccumulatedBaptismsByGroup] = useState(
    []
  )
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchTodaySummary = async () => {
      try {
        setLoading(true)
        const response = await axios.get('/api/v1/admin/summary/today-stat')
        if (response.data.success) {
          setSummary(response.data.data.summary)
          setBaptismsByGroup(response.data.data.baptismsByGroup)
        } else {
          toast.error(
            response.data.message || 'Failed to fetch today’s summary.'
          )
        }
      } catch (error) {
        console.error(
          'Error fetching today’s summary statistics:',
          error.message
        )
        toast.error('Error fetching today’s summary statistics.')
      } finally {
        setLoading(false)
      }
    }

    const fetchAccumulatedSummary = async () => {
      try {
        const response = await axios.get(
          '/api/v1/admin/summary/accumulated-stat'
        )
        if (response.data.success) {
          setAccumulatedSummary(response.data.data.summary)
          setAccumulatedBaptismsByGroup(response.data.data.baptismsByGroup)
        } else {
          toast.error(
            response.data.message || 'Failed to fetch accumulated summary.'
          )
        }
      } catch (error) {
        console.error(
          'Error fetching accumulated summary statistics:',
          error.message
        )
        toast.error('Error fetching accumulated summary statistics.')
      }
    }

    fetchTodaySummary()
    fetchAccumulatedSummary()
  }, [])

  const todayDate = new Date().toLocaleDateString('en-GB')

  return (
    <Layout title={'Summary Statistic'}>
      <div className='pt-3 mx-3'>
        <h5>Summary Statistic</h5>
        <div className='fw-bold mb-2'>Today's Date: {todayDate}</div>

        {loading ? (
          <p>Loading...</p>
        ) : summary ? (
          <div>
            <div>Contacts:</div>
            <ul>
              <li>
                Non-Christian - Very Open: {summary.nonChristianVOPP || 0}p/
                {summary.nonChristianVOUU || 0}u
              </li>
              <li>
                Non-Christian - Open: {summary.nonChristianOPP || 0}p/
                {summary.nonChristianOUU || 0}u
              </li>
              <li>
                Seeking Christians: {summary.christianPP || 0}p/
                {summary.christianUU || 0}u
              </li>
              <li>
                Home Meeting: {summary.homePP || 0}p/{summary.homeUU || 0}u
              </li>
            </ul>

            <div>
              <div>Baptism:</div>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                {baptismsByGroup.map((group, index) => (
                  <span key={group._id}>
                    {group.groupName}: {group.totalBaptism || 0}
                    {index < baptismsByGroup.length - 1 && ','}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <p>No data available for today.</p>
        )}
        <div className='fw-bold mt-3 mb-2'>
          Statistics from 28/12/2024 to {todayDate}
        </div>
        {loading ? (
          <p>Loading accumulated data...</p>
        ) : accumulatedSummary ? (
          <div>
            <div>Contacts:</div>
            <ul>
              <li>
                Non-Christian - Very Open:{' '}
                {accumulatedSummary.nonChristianVOPP || 0}p/
                {accumulatedSummary.nonChristianVOUU || 0}u
              </li>
              <li>
                Non-Christian - Open: {accumulatedSummary.nonChristianOPP || 0}
                p/
                {accumulatedSummary.nonChristianOUU || 0}u
              </li>
              <li>
                Seeking Christians: {accumulatedSummary.christianPP || 0}p/
                {accumulatedSummary.christianUU || 0}u
              </li>
              <li>
                Home Meeting: {accumulatedSummary.homePP || 0}p/
                {accumulatedSummary.homeUU || 0}u
              </li>
            </ul>

            <div>
              <div>Baptism:</div>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                {accumulatedBaptismsByGroup.map((group, index) => (
                  <span key={group._id}>
                    {group.groupName}: {group.totalBaptism || 0}
                    {index < accumulatedBaptismsByGroup.length - 1 && ','}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <p>No accumulated data available.</p>
        )}
      </div>
    </Layout>
  )
}

export default AdminSummaryStatistic
