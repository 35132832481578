import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { Modal } from 'react-bootstrap'
import AdminCreateUser from './CreateUser'
import AdminEditUser from './EditUser'
import { useNavigate } from 'react-router-dom'

const AdminUsersList = () => {
  const navigate = useNavigate()

  const [users, setUsers] = useState([])
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [editUserId, setEditUserId] = useState(null)

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await axios.get('/api/v1/admin/user/list')
        setUsers(data.users)
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    }
    fetchUsers()
  }, [])

  const refreshUserList = async () => {
    try {
      const { data } = await axios.get('/api/v1/admin/user/list')
      setUsers(data.users)
    } catch (error) {
      console.error('Error refreshing user list:', error)
    }
  }

  const groupedUsers = users.reduce((acc, user) => {
    const role =
      user.role === 1
        ? 'Admin'
        : user.role === 2
        ? 'Main Coordinator'
        : 'Coordinator'
    if (!acc[role]) acc[role] = []
    acc[role].push(user)
    return acc
  }, {})

  const handleShowCreateModal = () => setShowCreateModal(true)
  const handleCloseCreateModal = () => setShowCreateModal(false)

  const handleShowEditModal = (userId) => {
    setEditUserId(userId)
    setShowEditModal(true)
  }
  const handleCloseEditModal = () => {
    setEditUserId(null)
    setShowEditModal(false)
  }

  const handleReturnHome = () => {
    navigate('/admin/dashboard')
  }

  return (
    <Layout title={'Users'}>
      <div className='pt-3 mx-3'>
        <div className='text-end mb-3'>
          <button
            type='button'
            className='btn btn-outline-success btn-sm mx-3'
            onClick={handleShowCreateModal}
          >
            <i className='bi bi-plus-circle'></i> User
          </button>
          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={handleReturnHome}
          >
            <i className='bi bi-house-fill'></i>
          </button>
        </div>

        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>No.</th>
              <th scope='col'>Name</th>
              <th scope='col'>Username</th>
              <th scope='col'>Group</th>
              <th scope='col'>Team</th>
              <th scope='col'>Status</th>
              <th scope='col'></th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupedUsers).map(
              ([role, usersByRole], roleIndex) => (
                <React.Fragment key={role}>
                  <tr className='bg-light'>
                    <td
                      colSpan='8'
                      className='text-center'
                      style={{
                        backgroundColor: '#ecf2e2',
                        color: '#556B2F',
                        fontWeight: 'bold',
                      }}
                    >
                      {role}
                    </td>
                  </tr>
                  {usersByRole.map((user, index) => (
                    <tr key={user._id}>
                      <th scope='row'>{index + 1}</th>
                      <td>{user.name}</td>
                      <td>{user.username}</td>
                      <td>{user.groupId?.groupName}</td>
                      <td>{user.teamId?.teamName}</td>
                      <td
                        style={{ color: user.status === 1 ? 'green' : 'grey' }}
                      >
                        {user.status === 1 ? 'Active' : 'Inactive'}
                      </td>
                      <td className='text-end'>
                        <button
                          className='btn btn-sm btn-outline-primary'
                          onClick={() => handleShowEditModal(user._id)}
                        >
                          <i className='bi bi-pencil-square'></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              )
            )}
          </tbody>
        </table>

        <Modal show={showCreateModal} onHide={handleCloseCreateModal}>
          <AdminCreateUser
            onSuccess={() => {
              refreshUserList()
              handleCloseCreateModal()
            }}
            onCancel={handleCloseCreateModal}
          />
        </Modal>

        <Modal show={showEditModal} onHide={handleCloseEditModal}>
          {editUserId && (
            <AdminEditUser
              userId={editUserId}
              onSuccess={() => {
                refreshUserList()
                handleCloseEditModal()
              }}
              onCancel={handleCloseEditModal}
            />
          )}
        </Modal>
      </div>
    </Layout>
  )
}

export default AdminUsersList
