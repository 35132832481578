import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useAuth } from '../../../context/auth'

const AdminTeamBookSaleList = () => {
  const [auth] = useAuth()
  const [groups, setGroups] = useState([])

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const { data } = await axios.get('/api/v1/admin/summary/groups')
        if (data.success) {
          setGroups(data.data)
        }
      } catch (error) {
        console.error('Failed to fetch groups:', error.message)
      }
    }

    fetchGroups()
  }, [])

  return (
    <Layout title={'Team Book Sale Records'}>
      <div className='pt-3 mx-3'>
        <h5>Team Book Sale Records</h5>
        <div className='row'>
          {groups.map((group) => (
            <div
              key={group._id}
              className='col-12 col-md-4 mt-2 mb-2 gx-3 gy-3'
            >
              <div className='card'>
                <Link
                  to={`/admin/team-book-sale/${group._id}`}
                  className='btn btn-outline-success'
                  style={{ fontSize: '20px' }}
                >
                  {group.groupName}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default AdminTeamBookSaleList
