import React, { useRef } from 'react'

const MainNewConference = ({ onFormSubmit, onCancel }) => {
  const formRef = useRef(null)
  const today = new Date().toISOString().split('T')[0]

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const values = {
      date: formData.get('date'),
      event: formData.get('event'),
    }
    onFormSubmit(values)
    formRef.current.reset()
  }

  return (
    <div>
      <h5 className='mb-3'>Add New Conference</h5>
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className='mb-3'>
          <label htmlFor='date' className='form-label'>
            Date
          </label>
          <input
            type='date'
            id='date'
            name='date'
            className='form-control'
            defaultValue={today}
            required
          />
        </div>
        <div className='mb-3'>
          <label htmlFor='event' className='form-label'>
            Event
          </label>
          <input
            type='text'
            id='event'
            name='event'
            className='form-control'
            placeholder='Enter event name'
            required
          />
        </div>
        <div className='d-flex justify-content-end'>
          <button type='submit' className='btn btn-outline-success me-4 btn-sm'>
            Submit
          </button>
          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}

export default MainNewConference
