import React, { useState } from 'react'
import Layout from '../../../../components/Layout/Layout'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../context/auth'
import useIsMobile from './../../../../hooks/useIsMobile'

const NewSaleTamu = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const [auth] = useAuth()
  const userId = auth?.user?.id
  const groupId = auth?.user?.group
  const teamId = auth?.user?.team
  const [remark, setRemark] = useState('')

  const [searchTerm, setSearchTerm] = useState('')
  const [packingListData, setPackingListData] = useState([])
  const [selectedBooks, setSelectedBooks] = useState([])

  const today = new Date().toISOString().split('T')[0]
  const [recordDate, setRecordDate] = useState(today)

  const handleSearch = async (e) => {
    const term = e.target.value
    setSearchTerm(term)

    if (term.length > 3) {
      try {
        const { data } = await axios.get('/api/v1/user/response/search-book', {
          params: {
            search: term,
            groupId: groupId,
          },
        })
        setPackingListData(data)
      } catch (error) {
        console.error(
          'Failed to fetch packing list data:',
          error.response?.data?.message || error.message
        )
      }
    } else {
      setPackingListData([])
    }
  }

  const handleSelect = (item) => {
    setSelectedBooks((prevBooks) => [...prevBooks, { ...item, qty: 1 }])
    setSearchTerm('')
    setPackingListData([])
  }

  const handleQtyChange = (index, value) => {
    const updatedBooks = [...selectedBooks]
    updatedBooks[index].qty = value
    setSelectedBooks(updatedBooks)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (selectedBooks.length === 0) {
      toast.error('Buku perlu diisi')
      return
    }

    const formData = {
      bookRecord: selectedBooks.map((book) => ({
        packingListData: book._id,
        itemCode: book.itemCode,
        description: book.description,
        price: book.price,
        qty: book.qty,
      })),
      userId: userId,
      groupId: groupId,
      teamId: teamId,
      recordDate,
      remark,
    }

    try {
      await axios.post('/api/v1/user/sale-record/tamu-add', formData)
      toast.success('Berjaya dihantar!')
      setSelectedBooks([])
      setRecordDate(today)
      setRemark('')
      navigate('/user/tamu/sales-record')
    } catch (error) {
      console.error(
        'Failed to submit record:',
        error.response?.data || error.message
      )
      toast.error('Failed to submit')
    }
  }

  const handleRemoveRow = (indexToRemove) => {
    setSelectedBooks((prevBooks) =>
      prevBooks.filter((_, index) => index !== indexToRemove)
    )
  }

  const handleCancel = () => {
    navigate('/user/tamu/sales-record')
  }

  const handleReturnHome = () => {
    if (auth?.user?.role === 2) {
      navigate('/main/dashboard')
    } else {
      navigate('/user/dashboard')
    }
  }

  return (
    <Layout title={'Jualan Buku Baru'}>
      <div className='container pt-4 mb-5'>
        <div
          className={`d-flex mb-3 ${
            isMobile
              ? 'flex-column'
              : 'justify-content-between align-items-center'
          }`}
        >
          {isMobile && (
            <div className='d-flex justify-content-end w-100'>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm me-2'
                onClick={handleCancel}
              >
                Rekod Jualan Buku
              </button>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          )}

          <div
            className={`w-100 ${isMobile ? 'mt-2' : ''}`}
            style={{
              textAlign: isMobile ? 'left' : 'inherit',
            }}
          >
            <h5
              className='mb-0'
              style={{
                color: '#556B2F',
              }}
            >
              Jualan Buku Baru (Tamu)
            </h5>
          </div>

          {!isMobile && (
            <div className='d-flex justify-content-end w-100'>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm me-4'
                onClick={handleCancel}
              >
                Rekod Jualan Buku
              </button>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit}>
          <div className='mb-3'>
            <label htmlFor='recordDate' className='form-label'>
              Tarikh:
            </label>
            <input
              type='date'
              id='recordDate'
              value={recordDate}
              onChange={(e) => setRecordDate(e.target.value)}
              className='form-control'
              required
            />
          </div>

          <div className={`card ${isMobile ? 'p-2' : 'p-3'} mb-3`}>
            <div className='mb-3'>
              <label htmlFor='bookSearch' className='form-label'>
                Buku:
              </label>
              <Dropdown
                show={packingListData.length > 0 && searchTerm.length > 3}
              >
                <Dropdown.Toggle
                  as='input'
                  type='text'
                  id='bookSearch'
                  className='form-control'
                  placeholder='Cari Item Code / Nama Buku'
                  value={searchTerm}
                  onChange={handleSearch}
                  autoComplete='off'
                />
                <Dropdown.Menu
                  style={{
                    width: isMobile ? 'calc(100vw - 30px)' : '100%',
                    maxWidth: '100%',
                    left: isMobile ? '15px' : '0',
                    right: isMobile ? '15px' : '0',
                    position: 'absolute',
                    zIndex: 1050,
                    maxHeight: '300px',
                    overflowY: 'auto',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {packingListData.map((item) => {
                    const isSelected = selectedBooks.some(
                      (book) => book.itemCode === item.itemCode
                    )
                    return (
                      <Dropdown.Item
                        key={item._id}
                        onClick={() => !isSelected && handleSelect(item)}
                        disabled={isSelected}
                        style={{
                          cursor: isSelected ? 'not-allowed' : 'pointer',
                          color: isSelected ? 'gray' : 'inherit',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {item.itemCode} - {item.description}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div>
              {isMobile ? (
                <div className='table-responsive'>
                  <table className='table table-bordered'>
                    <thead>
                      <tr>
                        <th>Buku</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedBooks.length > 0 ? (
                        selectedBooks.map((book, index) => (
                          <tr key={index}>
                            <td>
                              <div className='d-flex justify-content-between align-items-start'>
                                <span>
                                  {book.itemCode} {book.description}
                                </span>
                                <span
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  RM {book.price.toFixed(2)}
                                </span>
                              </div>
                              <div className='d-flex justify-content-between align-items-center mt-1'>
                                <label>Qty:</label>
                                <input
                                  type='number'
                                  min='1'
                                  value={book.qty}
                                  onFocus={(e) => e.target.select()}
                                  onChange={(e) =>
                                    handleQtyChange(index, e.target.value)
                                  }
                                  className='form-control me-4 ms-2'
                                />
                                <i
                                  className='bi bi-trash3'
                                  style={{
                                    color: '#dc3545',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleRemoveRow(index)}
                                ></i>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan='2' className='text-center'>
                            Tiada buku dipilih
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <table className='table table-bordered'>
                  <thead>
                    <tr>
                      <th>Buku</th>
                      <th>Harga</th>
                      <th>Qty</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedBooks.length > 0 ? (
                      selectedBooks.map((book, index) => (
                        <tr key={index}>
                          <td>
                            {book.itemCode} {book.description}
                          </td>
                          <td className='text-end'>{book.price.toFixed(2)}</td>
                          <td>
                            <input
                              type='number'
                              min='1'
                              value={book.qty}
                              onFocus={(e) => e.target.select()}
                              onChange={(e) =>
                                handleQtyChange(index, e.target.value)
                              }
                              className='form-control'
                            />
                          </td>
                          <td className='text-center'>
                            <i
                              className='bi bi-trash3'
                              style={{ color: '#dc3545', cursor: 'pointer' }}
                              onClick={() => handleRemoveRow(index)}
                            ></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan='4' className='text-center'>
                          Tiada buku dipilih
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          <div className='mb-4'>
            <label htmlFor='remark' className='form-label'>
              Catatan:
            </label>
            <textarea
              id='remark'
              className='form-control'
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
            ></textarea>
          </div>

          <div className='text-end'>
            <button
              type='submit'
              className='btn btn-outline-primary btn-sm me-4'
            >
              Hantar
            </button>

            <button
              className='btn btn-outline-secondary btn-sm'
              onClick={handleCancel}
            >
              Batal
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default NewSaleTamu
