import React from 'react'

const truncateText = (text, wordLimit) => {
  if (!text) return ''
  const words = text.split(' ')
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...'
  }
  return text
}

const MainPackingListTable = ({
  packingDataList,
  editMode,
  editField,
  handleQtyChange,
  saveQty,
  handleQtyKeyPress,
  toggleEdit,
  openLogModal,
  lastElementRef,
  isMobile,
  handleOpenSaleLog,
}) => {
  function formatDate(dateString) {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  return (
    <table className='table actable' style={{ marginTop: '120px' }}>
      <thead>
        {isMobile ? (
          <tr>
            <th>Book</th>
            <th className='text-end'>Price</th>
            <th className='text-center'>Take</th>
          </tr>
        ) : (
          <tr>
            <th>Item Code</th>
            <th>Book</th>
            <th className='text-end'>Price</th>
            <th className='text-center'>Take</th>
            <th className='text-center'>Return</th>
            <th className='text-center'>Sale</th>
            <th>Last Updated</th>
          </tr>
        )}
      </thead>
      <tbody>
        {packingDataList.length > 0 ? (
          isMobile ? (
            // Mobile View
            packingDataList.map((item, index) => (
              <React.Fragment key={item._id}>
                <tr
                  ref={
                    index === packingDataList.length - 1 ? lastElementRef : null
                  }
                >
                  <td>
                    {item.itemCode} {truncateText(item.description, 10)}
                  </td>
                  <td className='text-end'>{item.price.toFixed(2)}</td>
                  <td className='text-center'>
                    {editMode === item._id && editField === 'takeQty' ? (
                      <input
                        type='number'
                        className='form-control'
                        style={{ width: '100px', margin: 'auto' }}
                        value={item.takeQty}
                        onChange={(e) =>
                          handleQtyChange(item._id, e.target.value, 'takeQty')
                        }
                        onBlur={() => saveQty(item._id, 'takeQty')}
                        onKeyDown={(e) =>
                          handleQtyKeyPress(e, item._id, 'takeQty')
                        }
                        onFocus={(e) => e.target.select()}
                        min='0'
                        autoFocus
                      />
                    ) : (
                      <span
                        onClick={() =>
                          toggleEdit(item._id, 'takeQty', item.takeQty)
                        }
                        className='px-3'
                        style={{ cursor: 'pointer' }}
                      >
                        {item.takeQty}
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan='3'>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <div>
                        Return:
                        {editMode === item._id && editField === 'returnQty' ? (
                          <input
                            type='number'
                            className='form-control'
                            style={{ margin: 'auto' }}
                            value={item.returnQty}
                            onChange={(e) =>
                              handleQtyChange(
                                item._id,
                                e.target.value,
                                'returnQty'
                              )
                            }
                            onBlur={() => saveQty(item._id, 'returnQty')}
                            onKeyDown={(e) =>
                              handleQtyKeyPress(e, item._id, 'returnQty')
                            }
                            onFocus={(e) => e.target.select()}
                            min='0'
                            autoFocus
                          />
                        ) : (
                          <span
                            onClick={() =>
                              toggleEdit(item._id, 'returnQty', item.returnQty)
                            }
                            className='px-2'
                            style={{ cursor: 'pointer' }}
                          >
                            {item.returnQty}
                          </span>
                        )}
                      </div>
                      <div>
                        Sale:
                        {item.saleQty > 0 ? (
                          <>
                            <i
                              className='bi-info-circle-fill mx-2'
                              style={{ color: '#ffc107', cursor: 'pointer' }}
                              onClick={() => handleOpenSaleLog(item)}
                            ></i>
                            {item.saleQty}
                          </>
                        ) : (
                          item.saleQty || 0
                        )}
                      </div>
                    </div>
                    <div>
                      Last Updated:
                      <span style={{ cursor: 'pointer' }}>
                        <i
                          className='bi-info-circle-fill mx-2'
                          style={{ color: '#00a6ff' }}
                          onClick={() => openLogModal(item._id)}
                        ></i>
                      </span>
                      {item.lastUptByCode ? item.lastUptByCode : ''}{' '}
                      {item.updatedAt ? formatDate(item.updatedAt) : ''}
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            ))
          ) : (
            // Desktop View
            packingDataList.map((item, index) => (
              <React.Fragment key={item._id}>
                <tr
                  ref={
                    index === packingDataList.length - 1 ? lastElementRef : null
                  }
                >
                  <td>{item.itemCode}</td>
                  <td>{truncateText(item.description, 10)}</td>
                  <td className='text-end'>{item.price.toFixed(2)}</td>
                  <td className='text-center'>
                    {editMode === item._id && editField === 'takeQty' ? (
                      <input
                        type='number'
                        className='form-control'
                        style={{ width: '100px', margin: 'auto' }}
                        value={item.takeQty}
                        onChange={(e) =>
                          handleQtyChange(item._id, e.target.value, 'takeQty')
                        }
                        onBlur={() => saveQty(item._id, 'takeQty')}
                        onKeyDown={(e) =>
                          handleQtyKeyPress(e, item._id, 'takeQty')
                        }
                        onFocus={(e) => e.target.select()}
                        min='0'
                        autoFocus
                      />
                    ) : (
                      <span
                        onClick={() =>
                          toggleEdit(item._id, 'takeQty', item.takeQty)
                        }
                        className='px-3'
                        style={{ cursor: 'pointer' }}
                      >
                        {item.takeQty}
                      </span>
                    )}
                  </td>
                  <td className='text-center'>
                    {editMode === item._id && editField === 'returnQty' ? (
                      <input
                        type='number'
                        className='form-control'
                        style={{ width: '100px', margin: 'auto' }}
                        value={item.returnQty}
                        onChange={(e) =>
                          handleQtyChange(item._id, e.target.value, 'returnQty')
                        }
                        onBlur={() => saveQty(item._id, 'returnQty')}
                        onKeyDown={(e) =>
                          handleQtyKeyPress(e, item._id, 'returnQty')
                        }
                        onFocus={(e) => e.target.select()}
                        min='0'
                        autoFocus
                      />
                    ) : (
                      <span
                        onClick={() =>
                          toggleEdit(item._id, 'returnQty', item.returnQty)
                        }
                        className='px-3'
                        style={{ cursor: 'pointer' }}
                      >
                        {item.returnQty}
                      </span>
                    )}
                  </td>
                  <td className='text-center'>
                    {item.saleQty > 0 ? (
                      <>
                        <i
                          className='bi-info-circle-fill mx-2'
                          style={{ color: '#ffc107', cursor: 'pointer' }}
                          onClick={() => handleOpenSaleLog(item)}
                        ></i>
                        {item.saleQty}
                      </>
                    ) : (
                      item.saleQty || 0
                    )}
                  </td>
                  <td>
                    <span style={{ cursor: 'pointer', marginRight: '10px' }}>
                      <i
                        className='bi-info-circle-fill'
                        style={{ color: '#00a6ff' }}
                        onClick={() => openLogModal(item._id)}
                      ></i>
                    </span>
                    {item.lastUptByCode ? item.lastUptByCode : ''}{' '}
                    {item.updatedAt ? formatDate(item.updatedAt) : ''}
                  </td>
                </tr>
              </React.Fragment>
            ))
          )
        ) : (
          <tr>
            <td colSpan={isMobile ? '3' : '7'} className='text-center'>
              No items found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default MainPackingListTable
