import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const AdminPackingListIndex = () => {
  const navigate = useNavigate()
  const [groups, setGroups] = useState([])

  const fetchGroups = async () => {
    try {
      const { data } = await axios.get('/api/v1/admin/packing-list/groups')
      if (data.success) {
        setGroups(data.data)
      } else {
        console.error('Failed to fetch groups')
      }
    } catch (error) {
      console.error('Error fetching groups:', error)
    }
  }

  useEffect(() => {
    fetchGroups()
  }, [])

  const handleViewDetails = (id) => {
    navigate(`/admin/packing-list/details/${id}`)
  }

  const handleReturnHome = () => {
    navigate('/admin/dashboard')
  }

  return (
    <Layout title={'Form 1 - Book Records'}>
      <div className='pt-3 pb-5 mx-3'>
        <div className='d-flex mb-2 justify-content-between align-items-center'>
          <div>
            <h5
              className='mb-0'
              style={{
                color: '#556B2F',
              }}
            >
              Form 1 - Book Records
            </h5>
          </div>
          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            onClick={handleReturnHome}
          >
            <i className='bi bi-house-fill'></i>
          </button>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Group</th>
              <th scope='col'></th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group) => (
              <tr key={group._id}>
                <td>{group.groupName}</td>
                <td className='text-end'>
                  <button
                    type='button'
                    className='btn btn-outline-primary btn-sm'
                    onClick={() => handleViewDetails(group._id)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default AdminPackingListIndex
