import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../context/auth'

const CreateNewPlace = ({ onClose, refreshPlaces }) => {
  const [category, setCategory] = useState(1)
  const [placeName, setPlaceName] = useState('')
  const [groupId, setGroupId] = useState('')
  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(false)

  const [auth] = useAuth()
  const userId = auth?.user?.id

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get('/api/v1/admin/place/group')
        setGroups(response.data)
      } catch (error) {
        console.error('Failed to fetch groups:', error)
        toast.error('Failed to load groups.')
      }
    }

    fetchGroups()
  }, [])

  const handleSave = async () => {
    if (!placeName) {
      toast.error('Place name is required.')
      return
    }
    if (!groupId) {
      toast.error('Please select a group.')
      return
    }
    setLoading(true)
    try {
      await axios.post('/api/v1/admin/place/add', {
        category,
        placeName,
        userId,
        groupId,
      })
      refreshPlaces()
      onClose()
    } catch (error) {
      console.error(error)
      toast.error('Failed to save the place.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className='modal-header'>
        <h5 className='modal-title' style={{ fontSize: '17px' }}>
          Add New Place
        </h5>
        <button type='button' className='btn-close' onClick={onClose}></button>
      </div>
      <div className='modal-body'>
        <div className='mb-3'>
          <label className='mb-2'>Category:</label>
          <div>
            <label className='me-3'>
              <input
                type='radio'
                value={1}
                checked={category === 1}
                onChange={() => setCategory(1)}
                style={{ marginRight: '8px' }}
              />
              Book Propagation
            </label>
            <label>
              <input
                type='radio'
                value={2}
                checked={category === 2}
                onChange={() => setCategory(2)}
                style={{ marginRight: '8px' }}
              />
              Tamu
            </label>
          </div>
        </div>
        <div className='mb-3'>
          <label className='mb-2'>Group:</label>
          <select
            className='form-control'
            value={groupId}
            onChange={(e) => setGroupId(e.target.value)}
          >
            <option value=''>Select Group</option>
            {groups.map((group) => (
              <option key={group._id} value={group._id}>
                {group.groupName}
              </option>
            ))}
          </select>
        </div>
        <div className='mb-3'>
          <label className='mb-2'>Place Name:</label>
          <input
            type='text'
            className='form-control'
            value={placeName}
            onChange={(e) => setPlaceName(e.target.value)}
          />
        </div>
      </div>
      <div className='modal-footer'>
        <button
          className='btn btn-outline-primary btn-sm me-3'
          onClick={handleSave}
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Submit'}
        </button>
        <button className='btn btn-outline-secondary btn-sm' onClick={onClose}>
          Cancel
        </button>
      </div>
    </>
  )
}

export default CreateNewPlace
