import React from 'react'
import Layout from '../../components/Layout/Layout'
import { Link, useNavigate } from 'react-router-dom'

const UserDashboard = () => {
  const navigate = useNavigate()

  const handleNewRespons = () => {
    navigate('/user/respons/new')
  }
  const handleNewSalesRecord = () => {
    navigate('/user/sales-record/new')
  }
  const handleNewStatistics = () => {
    navigate('/user/statistics/new')
  }

  const handleNewTamuRespons = () => {
    navigate('/user/tamu/respons/new')
  }
  const handleNewTamuSalesRecord = () => {
    navigate('/user/tamu/sales-record/new')
  }
  const handleNewTamuStatistics = () => {
    navigate('/user/tamu/statistics/new')
  }

  return (
    <Layout title={'Dashboard'}>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mt-4 fw-bold fs-5'>Penyebarluasan Buku</div>

          <div className='col-12 col-md-4 mt-2 gx-3 gy-3'>
            <div className='d-flex justify-content-between align-items-center p-2'>
              <Link
                to='/user/respons'
                className='btn btn-outline-success flex-grow-1'
                style={{ fontSize: '20px' }}
              >
                Kad Respon
              </Link>
              <button
                className='btn btn-outline-primary'
                onClick={handleNewRespons}
                style={{ padding: '0.5rem', fontSize: '16px', width: '20%' }}
              >
                <i className='bi bi-plus'></i>
              </button>
            </div>
          </div>

          <div className='col-12 col-md-4 mt-2 gx-3 gy-3'>
            <div className='d-flex justify-content-between align-items-center p-2'>
              <Link
                to='/user/sales-record'
                className='btn btn-outline-success flex-grow-1'
                style={{ fontSize: '20px' }}
              >
                Rekod Jualan Buku
              </Link>
              <button
                className='btn btn-outline-primary'
                onClick={handleNewSalesRecord}
                style={{ padding: '0.5rem', fontSize: '16px', width: '20%' }}
              >
                <i className='bi bi-plus'></i>
              </button>
            </div>
          </div>

          <div className='col-12 col-md-4 mt-2 gx-3 gy-3'>
            <div className='d-flex justify-content-between align-items-center p-2'>
              <Link
                to='/user/statistics'
                className='btn btn-outline-success flex-grow-1'
                style={{ fontSize: '20px' }}
              >
                Statistik
              </Link>
              <button
                className='btn btn-outline-primary'
                onClick={handleNewStatistics}
                style={{ padding: '0.5rem', fontSize: '16px', width: '20%' }}
              >
                <i className='bi bi-plus'></i>
              </button>
            </div>
          </div>

          <div className='col-12 mt-2 fw-bold fs-5'>Tamu</div>

          <div className='col-12 col-md-4 mt-2 gx-3 gy-3'>
            <div className='d-flex justify-content-between align-items-center p-2'>
              <Link
                to='/user/tamu/respons'
                className='btn btn-outline-success flex-grow-1'
                style={{ fontSize: '20px' }}
              >
                Kad Respon
              </Link>
              <button
                className='btn btn-outline-primary'
                onClick={handleNewTamuRespons}
                style={{ padding: '0.5rem', fontSize: '16px', width: '20%' }}
              >
                <i className='bi bi-plus'></i>
              </button>
            </div>
          </div>

          <div className='col-12 col-md-4 mt-2 gx-3 gy-3'>
            <div className='d-flex justify-content-between align-items-center p-2'>
              <Link
                to='/user/tamu/sales-record'
                className='btn btn-outline-success flex-grow-1'
                style={{ fontSize: '20px' }}
              >
                Rekod Jualan Buku
              </Link>
              <button
                className='btn btn-outline-primary'
                onClick={handleNewTamuSalesRecord}
                style={{ padding: '0.5rem', fontSize: '16px', width: '20%' }}
              >
                <i className='bi bi-plus'></i>
              </button>
            </div>
          </div>

          <div className='col-12 col-md-4 mt-2 gx-3 gy-3'>
            <div className='d-flex justify-content-between align-items-center p-2'>
              <Link
                to='/user/tamu/statistics'
                className='btn btn-outline-success flex-grow-1'
                style={{ fontSize: '20px' }}
              >
                Statistik
              </Link>
              <button
                className='btn btn-outline-primary'
                onClick={handleNewTamuStatistics}
                style={{ padding: '0.5rem', fontSize: '16px', width: '20%' }}
              >
                <i className='bi bi-plus'></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default UserDashboard
