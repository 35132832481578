import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import useIsMobile from '../../../hooks/useIsMobile'
import { useAuth } from '../../../context/auth'

const MainConferenceRecord = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const isMobile = useIsMobile()
  const [eventName, setEventName] = useState('')
  const [salesRecords, setSalesRecords] = useState([])
  const [totalSaleQty, setTotalSaleQty] = useState(0)
  const [totalSaleAmount, setTotalSaleAmount] = useState(0)
  const [loading, setLoading] = useState(false)

  const [auth] = useAuth()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const response = await axios.get(
          `/api/v1/main/conference/records/${id}`
        )
        if (response.data.success) {
          setEventName(response.data.conference.event)
          setSalesRecords(response.data.salesRecords)
          setTotalSaleQty(response.data.totalQty || 0)
          setTotalSaleAmount(response.data.totalAmount || 0)
        } else {
          toast.error(response.data.message || 'Failed to fetch data.')
        }
      } catch (error) {
        console.error('Error fetching data:', error.message)
      } finally {
        setLoading(false)
      }
    }

    if (id) {
      fetchData()
    }
  }, [id])

  const groupByOrderId = (records) => {
    return records.reduce((grouped, record) => {
      if (!grouped[record.conferenceOrderId]) {
        grouped[record.conferenceOrderId] = []
      }
      grouped[record.conferenceOrderId].push(record)
      return grouped
    }, {})
  }

  const groupedRecords = groupByOrderId(salesRecords)

  const handleVoid = (conferenceOrderId) => {
    if (window.confirm('Are you sure you want to void this record?')) {
      voidSalesRecord(conferenceOrderId)
    }
  }

  const voidSalesRecord = async (conferenceOrderId) => {
    try {
      const response = await axios.put(`/api/v1/main/conference/record/void`, {
        conferenceData: id,
        conferenceOrderId,
      })
      if (response.data.success) {
        setSalesRecords((prevRecords) => {
          const updatedRecords = prevRecords.map((record) =>
            record.conferenceOrderId === conferenceOrderId
              ? { ...record, status: 2 }
              : record
          )

          // Recalculate totals excluding voided records (status === 2)
          const filteredRecords = updatedRecords.filter(
            (record) => record.status !== 2
          )
          const newTotalQty = filteredRecords.reduce(
            (sum, record) => sum + record.qty,
            0
          )
          const newTotalAmount = filteredRecords.reduce(
            (sum, record) => sum + record.qty * record.price,
            0
          )

          // Update total sale qty and amount
          setTotalSaleQty(newTotalQty)
          setTotalSaleAmount(newTotalAmount)

          return updatedRecords
        })
      } else {
        toast.error(response.data.message || 'Failed to void record.')
      }
    } catch (error) {
      console.error('Error voiding record:', error.message)
      toast.error('Error voiding record.')
    }
  }

  const handleCancel = () => navigate('/main/conference')
  const handleReturnHome = () => navigate('/main/dashboard')

  return (
    <Layout title={'Conference Record'}>
      <div className='pt-3 mx-3'>
        <div
          className={`d-flex mb-2 ${
            isMobile
              ? 'flex-column'
              : 'justify-content-between align-items-center'
          }`}
        >
          {isMobile && (
            <div className='d-flex justify-content-end w-100'>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm me-3'
                onClick={handleCancel}
              >
                List
              </button>
              <button
                type='button'
                className='btn btn-outline-primary btn-sm me-3'
                onClick={() => navigate(`/main/conference/record/${id}`)}
              >
                Record
              </button>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          )}

          <div
            className={`w-100 ${isMobile ? 'mt-2' : ''}`}
            style={{ textAlign: isMobile ? 'left' : 'inherit' }}
          >
            <h5 className='mb-0' style={{ color: '#556B2F' }}>
              {eventName || 'Loading...'}
            </h5>
          </div>

          {!isMobile && (
            <div className='d-flex justify-content-end w-100'>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm me-3'
                onClick={handleCancel}
              >
                List
              </button>
              <button
                type='button'
                className='btn btn-outline-success btn-sm me-3'
                onClick={() => navigate(`/main/conference/sale/${id}`)}
              >
                Sale
              </button>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          )}
        </div>
        <div className='fw-bold'>
          Total Sale: RM {totalSaleAmount.toFixed(2)} ({totalSaleQty} Qty)
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Order ID</th>
              <th className='text-center'>Total Qty</th>
              <th className='text-end'>Total (RM)</th>
              <th>Remark</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan='6' className='text-center'>
                  Loading...
                </td>
              </tr>
            ) : Object.keys(groupedRecords).length > 0 ? (
              Object.keys(groupedRecords)
                .sort((a, b) => b - a) // Sorts in descending order
                .map((orderId) => {
                  const records = groupedRecords[orderId]
                  const firstRecord = records[0]
                  const totalQty = records.reduce(
                    (sum, record) => sum + record.qty,
                    0
                  )
                  const totalAmount = records.reduce(
                    (sum, record) => sum + record.qty * record.price,
                    0
                  )

                  return (
                    <React.Fragment key={orderId}>
                      {/* First Row */}
                      <tr
                        className={`conf-highlight ${
                          firstRecord.status === 2 ? 'text-muted' : ''
                        }`}
                      >
                        <td>{String(orderId).padStart(3, '0')}</td>
                        <td className='text-center'>{totalQty}</td>
                        <td className='text-end'>{totalAmount.toFixed(2)}</td>
                        <td>{firstRecord.remark || '-'}</td>
                        <td className='text-end'>
                          {firstRecord.status === 2 ? (
                            <span className='text-secondary'>Voided</span>
                          ) : (
                            <i
                              className='bi bi-trash-fill text-danger'
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                handleVoid(firstRecord.conferenceOrderId)
                              }
                            ></i>
                          )}
                        </td>
                      </tr>
                      {/* Second Row */}
                      <tr>
                        <td colSpan='5'>
                          {records.map((record, index) => (
                            <div key={record._id}>
                              {`${index + 1}. `}
                              <span className='me-2'>{record.itemCode}</span>
                              {record.description}
                              <span className='mx-1'>
                                x {record.qty}
                              </span> RM {record.price.toFixed(2)}
                            </div>
                          ))}
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })
            ) : (
              <tr>
                <td colSpan='5' className='text-center'>
                  No records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default MainConferenceRecord
