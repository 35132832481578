import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import useIsMobile from '../../../hooks/useIsMobile'
import { useAuth } from '../../../context/auth'

const StatisticList = () => {
  const navigate = useNavigate()
  const [auth] = useAuth()
  const userId = auth?.user?.id

  const isMobile = useIsMobile()
  const [lists, setLists] = useState([])

  useEffect(() => {
    const fetchList = async () => {
      if (!userId) {
        console.error('No userId found. Please log in.')
        navigate('/login')
        return
      }

      try {
        const { data } = await axios.get('/api/v1/user/statistic/lists', {
          params: { userId },
        })

        setLists(data)
      } catch (error) {
        console.error(
          'Failed to fetch lists:',
          error.response?.data || error.message
        )
      }
    }

    fetchList()
  }, [userId, navigate])

  const handleNewSale = () => {
    navigate('new')
  }

  const handleReturnHome = () => {
    if (auth?.user?.role === 2) {
      navigate('/main/dashboard')
    } else {
      navigate('/user/dashboard')
    }
  }

  const handleDelete = async (id) => {
    if (window.confirm('Confiirm delete?')) {
      try {
        await axios.delete(`/api/v1/user/statistic/delete/${id}`)
        setLists((prevLists) => prevLists.filter((item) => item._id !== id))
        toast.success('Deleted successfully!')
      } catch (error) {
        console.error('Failed to delete record:', error)
        toast.error('Failed to delete the record. Please try again.')
      }
    }
  }

  return (
    <Layout title={'Rekod Statistik'}>
      <div className={`pt-3 ${isMobile ? 'mx-2' : 'mx-3'}`}>
        <div
          className={`d-flex mb-3 ${
            isMobile
              ? 'flex-column'
              : 'justify-content-between align-items-center'
          }`}
        >
          {isMobile && (
            <div className='d-flex justify-content-end w-100'>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm me-2'
                onClick={handleNewSale}
              >
                <i className='bi bi-plus-circle'></i> Statistik
              </button>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          )}

          <div
            className={`w-100 ${isMobile ? 'mt-2' : ''}`}
            style={{
              textAlign: isMobile ? 'left' : 'inherit',
            }}
          >
            <h5
              className='mb-0'
              style={{
                color: '#556B2F',
              }}
            >
              Rekod Statistik (PB.)
            </h5>
          </div>

          {!isMobile && (
            <div className='d-flex justify-content-end w-100'>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm me-4'
                onClick={handleNewSale}
              >
                <i className='bi bi-plus-circle'></i> Statistik
              </button>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          )}
        </div>

        <table className='table'>
          <thead>
            {isMobile ? (
              <tr>
                <th scope='col' colSpan={2}>
                  Date
                </th>
              </tr>
            ) : (
              <>
                <tr>
                  <th
                    scope='col'
                    rowSpan='2'
                    style={{
                      verticalAlign: 'middle',
                    }}
                  >
                    Date
                  </th>

                  <th scope='col' colSpan='2' className='text-center'>
                    Non-Christian (P/U)
                  </th>
                  <th
                    scope='col'
                    className='text-center'
                    rowSpan='2'
                    style={{
                      verticalAlign: 'middle',
                    }}
                  >
                    Christian (P/U)
                    <br />
                    (Seeking)
                  </th>
                  <th
                    scope='col'
                    className='text-center'
                    rowSpan='2'
                    style={{
                      verticalAlign: 'middle',
                    }}
                  >
                    Home Meeting <div>(P/U)</div>
                  </th>
                  <th
                    scope='col'
                    className='text-center'
                    rowSpan='2'
                    style={{
                      verticalAlign: 'middle',
                    }}
                  >
                    Baptism
                  </th>
                  <th scope='col' className='text-center' rowSpan='2'></th>
                </tr>
                <tr>
                  <th
                    scope='col'
                    className='text-center'
                    style={{
                      borderTop: '1px solid #000',
                      borderRight: '1px solid #000',
                    }}
                  >
                    Very Open
                  </th>
                  <th
                    scope='col'
                    className='text-center'
                    style={{ borderTop: '1px solid #000' }}
                  >
                    Open
                  </th>
                </tr>
              </>
            )}
          </thead>
          <tbody>
            {lists.length > 0 ? (
              lists.map((item) => (
                <React.Fragment key={item._id}>
                  <tr>
                    <td
                      style={{
                        fontWeight: isMobile ? 'bold' : 'normal',
                        color: isMobile ? '#556B2F' : 'inherit',
                      }}
                    >
                      {new Date(item.recordDate).toLocaleDateString('en-GB', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}
                    </td>
                    {!isMobile && (
                      <>
                        <td className='text-center'>
                          {(item.nonChristianVOP > 0 ||
                            item.nonChristianVOU > 0) && (
                            <>
                              {item.nonChristianVOP}/{item.nonChristianVOU}
                            </>
                          )}
                        </td>
                        <td className='text-center'>
                          {(item.nonChristianOP > 0 ||
                            item.nonChristianOU > 0) && (
                            <>
                              {item.nonChristianOP}/{item.nonChristianOU}
                            </>
                          )}
                        </td>
                        <td className='text-center'>
                          {(item.christianP > 0 || item.christianU > 0) && (
                            <>
                              {item.christianP}/{item.christianU}
                            </>
                          )}
                        </td>
                        <td className='text-center'>
                          {(item.homeP > 0 || item.homeU > 0) && (
                            <>
                              {item.homeP}/{item.homeU}
                            </>
                          )}
                        </td>
                        <td className='text-center'>
                          {item.baptism > 0 && item.baptism}
                        </td>
                      </>
                    )}
                    <td className='text-end'>
                      <button
                        type='button'
                        className='btn btn-outline-primary btn-sm me-3'
                        onClick={() =>
                          navigate(`/user/statistics/edit/${item._id}`)
                        }
                      >
                        <i className='bi bi-pencil-square'></i>
                      </button>
                      <button
                        type='button'
                        className='btn btn-outline-danger btn-sm'
                        onClick={() => handleDelete(item._id)}
                      >
                        <i className='bi bi-trash-fill'></i>
                      </button>
                    </td>
                  </tr>
                  {isMobile && (
                    <tr>
                      <td colSpan='2'>
                        <strong>Non-Christian (P/U):</strong>
                        <br />
                        Very Open: {item.nonChristianVOP}/{item.nonChristianVOU}
                        <span className='ms-2'>
                          Open: {item.nonChristianOP}/{item.nonChristianOU}
                        </span>
                        <br />
                        <strong>Christian (P/U):</strong> {item.christianP}/
                        {item.christianU}
                        <br />
                        <strong>Home Meeting (P/U):</strong> {item.homeP}/
                        {item.homeU}
                        <br />
                        <strong>Baptism:</strong>{' '}
                        {item.baptism > 0 ? item.baptism : 0}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={isMobile ? '2' : '7'} className='text-center'>
                  Tiada rekod
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default StatisticList
