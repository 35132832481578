import React, { useCallback, useEffect, useRef, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../../context/auth'
import { Modal, Button } from 'antd'
import QtyLog from './QtyLog'
import ItemQty from './ItemQty'
import AdminPackingListTable from './PackingListTable'
import { Spinner } from 'react-bootstrap'
import { saveAs } from 'file-saver'

const AdminPackingListDetails = () => {
  const navigate = useNavigate()
  const { groupId } = useParams()
  const [groupName, setGroupName] = useState('')

  const [searchData, setSearchData] = useState('')
  const [packingDataList, setPackingDataList] = useState([])
  const [editMode, setEditMode] = useState(null)
  const [editField, setEditField] = useState(null)
  const [optionEnter, setOptionEnter] = useState('Take')
  const [filterQuery, setFilterQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [visibleLogModal, setVisibleLogModal] = useState(false)
  const [currentItemId, setCurrentItemId] = useState(null)
  const [currentItemDetails, setCurrentItemDetails] = useState({})
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const [visibleQtyModal, setVisibleQtyModal] = useState(false)
  const [originalValues, setOriginalValues] = useState({})
  const barcodeInputRef = useRef(null)
  const [focusBarcodeInput, setFocusBarcodeInput] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [totalPackingLists, setTotalPackingLists] = useState(0)

  const [searchBook, setSearchBook] = useState('')
  const [bookSearchResults, setBookSearchResults] = useState([])

  const masterFileImport = useRef(null)
  const [isLoadingMasterImport, setIsLoadingMasterImport] = useState(false)
  const [masterReportfile, setMasterReportfile] = useState(null)

  const [auth] = useAuth()
  const userId = auth?.user?.id

  const [quantities, setQuantities] = useState({
    totalItems: 0,
    totalTakeQty: 0,
    totalReturnQty: 0,
    totalSaleQty: 0,
  })

  useEffect(() => {
    const fetchGroupName = async () => {
      try {
        const response = await axios.get(
          `/api/v1/admin/packing-list/group/${groupId}`
        )
        if (response.data.success) {
          setGroupName(response.data.data.groupName)
        }
      } catch (error) {
        console.error('Error fetching group name:', error)
      }
    }

    if (groupId) {
      fetchGroupName()
    }
  }, [groupId])

  useEffect(() => {
    const shouldFetch = filterQuery.length > 3 || filterQuery === ''
    if (shouldFetch) {
      fetchData(page)
    }
  }, [page, filterQuery])

  useEffect(() => {
    if (filterQuery.length > 3 || filterQuery === '') {
      setPage(1)
    }
  }, [filterQuery])

  useEffect(() => {
    if (focusBarcodeInput) {
      barcodeInputRef.current?.focus()
      setFocusBarcodeInput(false)
    } else if (optionEnter) {
      barcodeInputRef.current?.focus()
    }
  }, [focusBarcodeInput, optionEnter])

  useEffect(() => {
    if (visibleQtyModal) {
      const fetchQtyData = async () => {
        try {
          const response = await axios.get(
            `/api/v1/main/book-record/quantities/${groupId}`
          )
          setQuantities(response.data)
        } catch (error) {
          console.error('Error fetching quantities:', error)
        }
      }
      fetchQtyData()
    }
  }, [visibleQtyModal])

  const fetchData = async (newPage = 1, showToast = false) => {
    setIsLoading(true)
    try {
      const response = await axios.get('/api/v1/main/book-record/details', {
        params: {
          groupId,
          userId,
          page: newPage,
          limit: 30,
          search: filterQuery,
        },
      })

      if (newPage === 1) {
        setPackingDataList(response.data.data)
      } else {
        setPackingDataList((prev) => [...prev, ...response.data.data])
      }
      setTotalPackingLists(response.data.total)
      setHasMore(response.data.page < response.data.totalPages)
      if (showToast) {
        toast.success('Data is up to date')
      }
    } catch (error) {
      console.error('Failed to fetch packing list data:', error)
      setPackingDataList([])
    } finally {
      setIsLoading(false)
    }
  }

  const observer = useRef()
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, hasMore]
  )

  const toggleEdit = (itemId, field, currentValue) => {
    if (editMode === itemId && editField === field) {
      setEditMode(null)
      setEditField(null)
      setPackingDataList((currentList) =>
        currentList.map((item) =>
          item._id === itemId
            ? {
                ...item,
                [field]: originalValues[`${itemId}-${field}`] || item[field],
              }
            : item
        )
      )
    } else {
      setEditMode(itemId)
      setEditField(field)
      setOriginalValues((prev) => ({
        ...prev,
        [`${itemId}-${field}`]: currentValue,
      }))
    }
  }

  const handleQtyChange = (itemId, value, type) => {
    setPackingDataList((currentList) =>
      currentList.map((item) =>
        item._id === itemId ? { ...item, [type]: value } : item
      )
    )
  }

  const saveQty = async (itemId, type) => {
    const item = packingDataList.find((item) => item._id === itemId)
    if (!item) return
    const originalValue = Number(
      String(originalValues[`${itemId}-${type}`]).trim()
    )
    const updatedValue = Number(String(item[type]).trim())
    if (originalValue === updatedValue) {
      setEditMode(null)
      setEditField(null)
      setFocusBarcodeInput(true)
      return
    }
    try {
      await axios.patch(`/api/v1/main/book-record/update-item/${itemId}`, {
        [type]: updatedValue,
        userId,
      })

      const userCode = auth?.user?.code
      const updatedProduct = {
        ...item,
        checkQty: updatedValue,
        lastUptByCode: userCode,
        updatedAt: new Date(),
      }

      setPackingDataList((prevItems) => [
        updatedProduct,
        ...prevItems.filter((item) => item._id !== itemId),
      ])

      setEditMode(null)
      setEditField(null)
      setFocusBarcodeInput(true)
    } catch (error) {
      console.error(`Failed to update ${type}:`, error)
      toast.error(`Failed to update ${type}.`)
    }
  }

  const scanResult = async () => {
    try {
      const { data } = await axios.post(
        '/api/v1/admin/packing-list/scan-stock',
        {
          searchData,
          action: optionEnter,
          userId,
          groupId,
        }
      )
      if (data.products.length === 0) {
        toast.info('No records found')
      } else {
        if (data.updatedProducts && data.updatedProducts.length > 0) {
          updateScannedProduct(data.updatedProducts[0])
        }
      }
    } catch (error) {
      toast.error('This book does not exist')
    } finally {
      clearInput()
    }
  }

  const updateScannedProduct = (scannedProduct) => {
    const userCode = auth?.user?.username

    setPackingDataList((prevItems) => {
      const existingItem = prevItems.find(
        (item) => item._id === scannedProduct._id
      )
      const updatedProduct = {
        ...scannedProduct,
        lastUptByCode: userCode,
        updatedAt: new Date(),
      }
      const isItemInList = existingItem !== undefined

      let updatedItems
      if (isItemInList) {
        updatedItems = [
          updatedProduct,
          ...prevItems.filter((item) => item._id !== scannedProduct._id),
        ]
      } else {
        if (totalPackingLists >= 30) {
          updatedItems = [
            updatedProduct,
            ...prevItems.slice(0, prevItems.length - 1),
          ]
        } else {
          updatedItems = [updatedProduct, ...prevItems]
        }
      }

      return updatedItems
    })
  }

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter' && searchData.trim()) {
      await scanResult()
    }
  }

  const handleQtyKeyPress = (event, itemId, type) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      saveQty(itemId, type)
    }
  }

  const fetchSuggestions = async (query) => {
    if (query.length <= 3) return
    try {
      const { data } = await axios.get('/api/v1/main/book-record/search-book', {
        params: { search: query },
      })
      setBookSearchResults(data)
    } catch (error) {
      console.error('Error fetching suggestions:', error)
    }
  }

  const handleSearchChange = (e) => {
    const value = e.target.value
    setSearchBook(value)
    if (value.length > 3) {
      fetchSuggestions(value)
    } else {
      setBookSearchResults([])
    }
  }

  const addToPackingList = async (item) => {
    try {
      const response = await axios.post(
        '/api/v1/admin/packing-list/add-to-packing-list',
        {
          itemCode: item.itemCode,
          action: optionEnter,
          userId,
          groupId,
        }
      )

      if (
        response.data.updatedProducts &&
        response.data.updatedProducts.length > 0
      ) {
        updateScannedProduct(response.data.updatedProducts[0])
        setSearchBook('')
        setBookSearchResults([])
      } else {
        toast.info('No updates made.')
      }
    } catch (error) {
      console.error('Error adding item to packing list:', error)
      toast.error('Failed to add item to packing list')
    }
  }

  const deleteData = async (itemId) => {
    try {
      if (window.confirm('Confirm Delete?')) {
        await axios.delete(`/api/v1/main/book-record/details/${itemId}`)
        setPackingDataList((prevData) =>
          prevData.filter((item) => item._id !== itemId)
        )
      }
    } catch (error) {
      console.error('Failed to delete item:', error)
      toast.error('Failed to delete item')
    }
  }

  const clearInput = () => {
    setSearchData('')
    setFocusBarcodeInput(true)
  }

  const getSearchContent = (value) => {
    setSearchData(value)
  }

  const handleFilterChange = (value) => {
    setFilterQuery(value)
  }

  const closeModal = (e) => {
    e.preventDefault()
    setVisibleLogModal(false)
  }

  const openLogModal = (itemId) => {
    const item = packingDataList.find((item) => item._id === itemId)
    if (item) {
      setCurrentItemId(itemId)
      setCurrentItemDetails({
        itemCode: item.itemCode,
        description: item.description,
      })
      setVisibleLogModal(true)
    }
  }

  const handleImportMasterFile = (e) => {
    setMasterReportfile(e.target.files[0])
  }

  const uploadMasterFile = async (e) => {
    e.preventDefault()
    if (!masterReportfile) {
      alert('Please upload Master File.')
      return
    }

    const formData = new FormData()
    formData.append('file', masterReportfile)
    formData.append('userId', userId)
    setIsLoadingMasterImport(true)

    try {
      const response = await fetch(
        `/api/v1/admin/packing-list/import/master/${groupId}`,
        {
          userId,
          method: 'POST',
          body: formData,
        }
      )

      if (response.ok) {
        const result = await response.text()
        console.log(result)

        setMasterReportfile(null)
        masterFileImport.current.value = ''

        fetchData()
      } else {
        throw new Error('Upload was not successful')
      }
    } catch (error) {
      console.error('Upload failed:', error)
      alert('Upload failed')
    }
    setIsLoadingMasterImport(false)
  }

  const exportFile = async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/packing-list/export/${groupId}`,
        { responseType: 'blob' }
      )

      const contentDisposition = response.headers['content-disposition']
      let filename = 'form_1.xlsx'
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/)
        if (filenameMatch.length === 2) {
          filename = filenameMatch[1]
        }
      }

      saveAs(response.data, filename)
    } catch (error) {
      console.error('Failed to download the file:', error)
      toast.error('Failed to download the file.')
    }
  }

  const handleCancel = () => {
    navigate('/admin/packing-list')
  }
  const handleReturnHome = () => {
    navigate('/admin/dashboard')
  }

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ')
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...'
    }
    return text
  }

  return (
    <Layout title={'Packing List'}>
      <div
        className='shadow-sm p-3 bg-white rounded'
        style={{ width: '100%', position: 'fixed', height: '140px' }}
      >
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <div className='fw-bold'>
            {'Form 1 - Book Records' + (groupName ? ` [${groupName}]` : '')}
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm me-3'
              onClick={handleCancel}
            >
              Form 1 - Book Records
            </button>
            <button
              type='button'
              className='btn btn-outline-secondary btn-sm'
              onClick={handleReturnHome}
            >
              <i className='bi bi-house-fill'></i>
            </button>
          </div>
        </div>

        <div className='d-flex justify-content-between mb-2'>
          <div className='d-flex flex-column' style={{ width: '30%' }}>
            <div className='d-flex align-items-center'>
              <input
                type='text'
                className='form-control py-1'
                placeholder='Search Book Only to Enter'
                value={searchBook}
                onChange={handleSearchChange}
                style={{ flex: 1 }}
              />
              <button
                className='btn btn-outline-secondary btn-sm'
                onClick={() => {
                  setSearchBook('')
                  setBookSearchResults([])
                }}
              >
                Clear
              </button>
            </div>
            {bookSearchResults.length > 0 && (
              <div>
                <ul className='dropdown-menu show' style={{ width: '50%' }}>
                  {bookSearchResults.map((item) => (
                    <li
                      key={item._id}
                      className='dropdown-item d-flex justify-content-between align-items-center'
                    >
                      {truncateText(item.description, 8)}
                      <button
                        className='btn btn-outline-primary btn-sm ms-3'
                        onClick={() => addToPackingList(item)}
                      >
                        <i className='bi bi-check'></i>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className='d-flex'>
            <input
              type='text'
              className='form-control py-1'
              style={{ width: '350px' }}
              placeholder='Search Code/Book in the list below'
              onChange={(e) => handleFilterChange(e.target.value)}
              value={filterQuery}
            />
            <button
              className='btn btn-outline-secondary btn-sm'
              onClick={() => setFilterQuery('')}
            >
              Clear
            </button>
          </div>

          <div className='d-flex align-items-center'>
            <input
              type='file'
              className='form-control py-1'
              style={{ width: '250px' }}
              onChange={handleImportMasterFile}
              ref={masterFileImport}
            />
            <div className='text-center'>
              {isLoadingMasterImport ? (
                <Spinner />
              ) : (
                <button
                  type='button'
                  className='btn btn-outline-success btn-sm'
                  onClick={uploadMasterFile}
                >
                  Import Data
                </button>
              )}
            </div>
          </div>
        </div>
        <div className='col-auto' style={{ display: 'flex' }}>
          <div
            style={{
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              <input
                type='radio'
                id='takeRadio'
                name='action'
                value='Take'
                checked={optionEnter === 'Take'}
                onChange={() => setOptionEnter('Take')}
              />
              <label
                htmlFor='takeRadio'
                style={{ marginRight: '10px', marginLeft: '5px' }}
              >
                Take
              </label>
            </div>
            <div>
              <input
                type='radio'
                id='returnRadio'
                name='action'
                value='Return'
                checked={optionEnter === 'Return'}
                onChange={() => setOptionEnter('Return')}
              />
              <label
                htmlFor='returnRadio'
                style={{ marginRight: '10px', marginLeft: '5px' }}
              >
                Return
              </label>
            </div>
          </div>
          <input
            type='text'
            className='form-control py-1'
            value={searchData}
            placeholder='Enter Book Code Only to the List'
            onChange={(e) => getSearchContent(e.target.value)}
            onKeyDown={handleKeyPress}
            style={{ flex: 1 }}
            ref={barcodeInputRef}
            autoFocus
          />
          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            disabled={!searchData}
            onClick={clearInput}
            style={{ marginLeft: '0px' }}
          >
            Clear
          </button>

          <button
            type='button'
            className='btn btn-outline-primary btn-sm ms-5'
            onClick={() => exportFile()}
          >
            Export List
          </button>

          <button
            type='button'
            className='btn btn-outline-info btn-sm'
            style={{ marginLeft: '15px' }}
            onClick={() => {
              setVisibleQtyModal(true)
            }}
          >
            Qty Details
          </button>

          <button
            type='button'
            className='btn btn-outline-info btn-sm ms-3'
            onClick={() => fetchData(1, true)}
          >
            <i className='bi bi-arrow-clockwise'></i>
          </button>
        </div>
      </div>

      <div>
        <div className='table-responsive'>
          <AdminPackingListTable
            packingDataList={packingDataList}
            lastElementRef={lastElementRef}
            editMode={editMode}
            editField={editField}
            handleQtyChange={handleQtyChange}
            saveQty={saveQty}
            handleQtyKeyPress={handleQtyKeyPress}
            toggleEdit={toggleEdit}
            openLogModal={openLogModal}
            deleteData={deleteData}
          />
        </div>
      </div>

      <Modal
        closable={false}
        onCancel={() => setVisibleLogModal(false)}
        open={visibleLogModal}
        width={'70%'}
        footer={[
          <Button key='cancel' onClick={() => setVisibleLogModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <QtyLog
          closeLogModal={closeModal}
          itemId={currentItemId}
          itemDetails={currentItemDetails}
          lastUpdate={lastUpdate}
        />
      </Modal>

      <Modal
        closable={false}
        onCancel={() => setVisibleQtyModal(false)}
        open={visibleQtyModal}
        footer={[
          <Button key='cancel' onClick={() => setVisibleQtyModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <ItemQty
          totalItems={quantities.totalItems}
          totalTakeQty={quantities.totalTakeQty}
          totalReturnQty={quantities.totalReturnQty}
          totalSaleQty={quantities.totalSaleQty}
        />
      </Modal>
    </Layout>
  )
}

export default AdminPackingListDetails
