import { Routes, Route } from 'react-router-dom'
import { useAuth } from './context/auth'

import PrivateRoute from './components/Routes/PrivateRoute'
import PageNotFound from './pages/PageNotFound'
import HomePage from './pages/HomePage'
import Login from './pages/Auth/Login'
import AdminRoute from './components/Routes/AdminRoute'
import UserRoute from './components/Routes/UserRoute'
import AdminDashboard from './pages/Admin/Dashboard'
import AdminUsersList from './pages/Admin/Users/UserList'
import AdminGroupList from './pages/Admin/Groups/GroupList'
import AdminBookStockList from './pages/Admin/BookStock/BookStockList'
import MainCoordinatorRoute from './components/Routes/MainCoordinatorRoute'
import MainCoordinatorDashboard from './pages/MainCoordinator/Dashboard'
import MainPackingListDetails from './pages/MainCoordinator/PackingList/PackingListDetails'
import UserDashboard from './pages/User/Dashboard'
import ResponseList from './pages/User/Respon/ResponseList'
import ResponseCard from './pages/User/Respon/ResponseCard'
import ResponseCardEdit from './pages/User/Respon/ResponseCardEdit'
import SaleRecordList from './pages/User/SaleRecord/SaleRecordList'
import NewSale from './pages/User/SaleRecord/NewSale'
import StatisticList from './pages/User/Statistics/StatisticList'
import NewStatistic from './pages/User/Statistics/NewStatistic'
import EditStatistic from './pages/User/Statistics/EditStatistic'
import AdminPackingListIndex from './pages/Admin/PackingList/PackingListIndex'
import ResponseListTamu from './pages/User/Tamu/Respon/ResponseList'
import ResponseCardTamu from './pages/User/Tamu/Respon/ResponseCard'
import AdminPackingListDetails from './pages/Admin/PackingList/PackingListDetails'
import AdminPlaceList from './pages/Admin/Place/PlaceList'
import MainSaleRecordList from './pages/MainCoordinator/SaleRecords/SaleRecordList'
import MainStatisticList from './pages/MainCoordinator/Statistic/StatisticList'
import SaleRecordListTamu from './pages/User/Tamu/SaleRecord/SaleRecordList'
import NewSaleTamu from './pages/User/Tamu/SaleRecord/NewSale'
import ResponseCardEditTamu from './pages/User/Tamu/Respon/ResponseCardEdit'
import StatisticListTamu from './pages/User/Tamu/Statistics/StatisticList'
import NewStatisticTamu from './pages/User/Tamu/Statistics/NewStatistic'
import EditStatisticTamu from './pages/User/Tamu/Statistics/EditStatistic'
import MainConferenceList from './pages/MainCoordinator/Conference/ConferenceList'
import MainConferenceSale from './pages/MainCoordinator/Conference/ConferenceSale'
import MainConferenceRecord from './pages/MainCoordinator/Conference/ConferenceRecord'
import AdminSummaryStatistic from './pages/Admin/Summary/SummaryStatistic'
import AdminSummaryBook from './pages/Admin/Summary/SummaryBook'
import AdminTeamBookSaleList from './pages/Admin/BookSale/TeamList'
import AdminTeamStatisticList from './pages/Admin/Statistic/TeamList'
import AdminTeamBookSaleData from './pages/Admin/BookSale/TeamData'
import AdminTeamStatisticData from './pages/Admin/Statistic/TeamListData'
import AdminSummaryConference from './pages/Admin/Summary/SummaryConference'
import AdminGroupSaleList from './pages/Admin/GroupSale/GroupSaleList'
import AdminContactNameList from './pages/Admin/ContactList/ContactNameList'

function App() {
  const [auth] = useAuth()
  const userRole = auth?.user?.role

  return (
    <>
      <Routes>
        <Route path='/' element={<PrivateRoute />}>
          <Route path='/' element={<HomePage />} />
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='*' element={<PageNotFound />} />

        {/* Admin */}
        <Route path='/admin' element={<AdminRoute />}>
          <Route path='dashboard' element={<AdminDashboard />} />
          <Route path='book-stock' element={<AdminBookStockList />} />
          <Route path='summary-book' element={<AdminSummaryBook />} />
          <Route path='summary-statistic' element={<AdminSummaryStatistic />} />
          <Route
            path='summary-conference'
            element={<AdminSummaryConference />}
          />

          <Route path='group-book-sale' element={<AdminGroupSaleList />} />
          <Route path='team-book-sale' element={<AdminTeamBookSaleList />} />
          <Route
            path='team-book-sale/:groupId'
            element={<AdminTeamBookSaleData />}
          />
          <Route path='team-statistic' element={<AdminTeamStatisticList />} />
          <Route
            path='team-statistic/:groupId'
            element={<AdminTeamStatisticData />}
          />

          <Route path='packing-list' element={<AdminPackingListIndex />} />
          <Route
            path='packing-list/details/:groupId'
            element={<AdminPackingListDetails />}
          />
          <Route path='users' element={<AdminUsersList />} />
          <Route path='group' element={<AdminGroupList />} />
          <Route path='places' element={<AdminPlaceList />} />
          <Route path='contact-list' element={<AdminContactNameList />} />
        </Route>

        {/* Main */}
        {userRole === 1 || userRole === 2 ? (
          <>
            <Route
              path='/main/dashboard'
              element={<MainCoordinatorDashboard />}
            />
            <Route
              path='/main/book-records'
              element={<MainPackingListDetails />}
            />
            <Route path='/main/sales-record' element={<MainSaleRecordList />} />
            <Route path='/main/statistics' element={<MainStatisticList />} />
            <Route path='/main/conference' element={<MainConferenceList />} />
            <Route
              path='/main/conference/sale/:id'
              element={<MainConferenceSale />}
            />
            <Route
              path='/main/conference/record/:id'
              element={<MainConferenceRecord />}
            />
          </>
        ) : (
          <Route path='/main' element={<MainCoordinatorRoute />}>
            <Route path='dashboard' element={<MainCoordinatorDashboard />} />
            <Route path='book-records' element={<MainPackingListDetails />} />
            <Route path='sales-record' element={<MainSaleRecordList />} />
            <Route path='statistics' element={<MainStatisticList />} />
            <Route path='conference' element={<MainConferenceList />} />
            <Route
              path='conference/sale/:id'
              element={<MainConferenceSale />}
            />
            <Route
              path='conference/record/:id'
              element={<MainConferenceRecord />}
            />
          </Route>
        )}

        {/* User */}
        {userRole === 1 || userRole === 2 ? (
          <>
            <Route path='/user/dashboard' element={<UserDashboard />} />
            <Route path='/user/respons' element={<ResponseList />} />
            <Route path='/user/respons/new' element={<ResponseCard />} />
            <Route
              path='/user/respons/edit/:id'
              element={<ResponseCardEdit />}
            />
            <Route path='/user/sales-record' element={<SaleRecordList />} />
            <Route path='/user/sales-record/new' element={<NewSale />} />
            <Route path='/user/statistics' element={<StatisticList />} />
            <Route path='/user/statistics/new' element={<NewStatistic />} />
            <Route
              path='/user/statistics/edit/:id'
              element={<EditStatistic />}
            />
            <Route path='/user/tamu/respons' element={<ResponseListTamu />} />
            <Route
              path='/user/tamu/respons/new'
              element={<ResponseCardTamu />}
            />
            <Route
              path='/user/tamu/respons/edit/:id'
              element={<ResponseCardEditTamu />}
            />

            <Route
              path='/user/tamu/sales-record'
              element={<SaleRecordListTamu />}
            />
            <Route
              path='/user/tamu/sales-record/new'
              element={<NewSaleTamu />}
            />
            <Route
              path='/user/tamu/statistics'
              element={<StatisticListTamu />}
            />
            <Route
              path='/user/tamu/statistics/new'
              element={<NewStatisticTamu />}
            />
            <Route
              path='/user/tamu/statistics/edit/:id'
              element={<EditStatisticTamu />}
            />
          </>
        ) : (
          <Route path='/user' element={<UserRoute />}>
            <Route path='dashboard' element={<UserDashboard />} />
            <Route path='respons' element={<ResponseList />} />
            <Route path='respons/new' element={<ResponseCard />} />
            <Route path='respons/edit/:id' element={<ResponseCardEdit />} />
            <Route path='sales-record' element={<SaleRecordList />} />
            <Route path='sales-record/new' element={<NewSale />} />
            <Route path='statistics' element={<StatisticList />} />
            <Route path='statistics/new' element={<NewStatistic />} />
            <Route path='statistics/edit/:id' element={<EditStatistic />} />
            {/* Tamu */}
            <Route path='tamu/respons' element={<ResponseListTamu />} />
            <Route path='tamu/respons/new' element={<ResponseCardTamu />} />
            <Route
              path='tamu/respons/edit/:id'
              element={<ResponseCardEditTamu />}
            />
            <Route path='tamu/sales-record' element={<SaleRecordListTamu />} />
            <Route path='tamu/sales-record/new' element={<NewSaleTamu />} />
            <Route path='tamu/statistics' element={<StatisticListTamu />} />
            <Route path='tamu/statistics/new' element={<NewStatisticTamu />} />
            <Route
              path='tamu/statistics/edit/:id'
              element={<EditStatisticTamu />}
            />
          </Route>
        )}
      </Routes>
    </>
  )
}

export default App
