import React, { useEffect, useState } from 'react'
import axios from 'axios'

const QtyLog = ({ closeLogModal, itemId, itemDetails, lastUpdate }) => {
  const [logs, setLogs] = useState([])

  useEffect(() => {
    if (itemId) {
      const fetchLogs = async () => {
        try {
          const response = await axios.get(
            `/api/v1/main/book-record/logs/${itemId}`
          )
          const data = response.data

          const maxLength = Math.max(
            data.takeQtyLog.length,
            data.returnQtyLog.length
          )
          const combinedLogs = []

          for (let i = 0; i < maxLength; i++) {
            combinedLogs.push({
              takeLog: data.takeQtyLog[i],
              returnLog: data.returnQtyLog[i],
            })
          }

          setLogs(combinedLogs)
        } catch (error) {
          console.error('Failed to fetch logs', error)
          setLogs([])
        }
      }

      fetchLogs()
    }
  }, [itemId, itemDetails, lastUpdate])

  function formatDate(dateString) {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  return (
    <>
      <div className='text-center h5 shadow-sm pb-3 bg-white rounded'>
        Qty Logs for {itemDetails.itemCode} {itemDetails.description}
      </div>
      <div className='table-responsive'>
        <table className='table'>
          <thead>
            <tr>
              <th colSpan='4' className='text-center'>
                Take
              </th>
              <th colSpan='4' className='text-center'>
                Return
              </th>
            </tr>
            <tr>
              {/* Take Columns */}
              <th>Date</th>
              <th>User</th>
              <th>Qty</th>
              <th>Type</th>
              {/* Return Columns */}
              <th>Date</th>
              <th>User</th>
              <th>Qty</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log, index) => (
              <tr key={index}>
                {/* Take Log Details */}
                <td>
                  {log.takeLog ? formatDate(log.takeLog.takeDateTime) : ''}
                </td>
                <td>{log.takeLog ? log.takeLog.user.username : ''}</td>
                <td>{log.takeLog ? log.takeLog.qty : ''}</td>
                <td>{log.takeLog ? log.takeLog.type : ''}</td>

                <td>
                  {log.returnLog
                    ? formatDate(log.returnLog.returnDateTime)
                    : ''}
                </td>
                <td>{log.returnLog ? log.returnLog.user.username : ''}</td>
                <td>{log.returnLog ? log.returnLog.qty : ''}</td>
                <td>{log.returnLog ? log.returnLog.type : ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default QtyLog
