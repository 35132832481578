import React, { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import axios from 'axios'
import { toast } from 'react-toastify'

const AdminEditBook = ({ show, onClose, bookId, onUpdateBook }) => {
  const [formData, setFormData] = useState({
    itemCode: '',
    description: '',
    price: '',
    itemType: '',
    barCode: '',
    subsidized: '0',
    selectForStat: '0',
  })

  const [categories, setCategories] = useState([])
  const [isCategoryLoading, setIsCategoryLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoadingBookData, setIsLoadingBookData] = useState(true)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const fetchCategories = async () => {
    setIsCategoryLoading(true)
    try {
      const { data } = await axios.get(
        '/api/v1/admin/bookstock/search-category-add'
      )
      setCategories(data)
    } catch (error) {
      console.error('Error fetching categories:', error)
      toast.error('Failed to load categories.')
    } finally {
      setIsCategoryLoading(false)
    }
  }

  const fetchBookData = async () => {
    setIsLoadingBookData(true)
    try {
      const { data } = await axios.get(`/api/v1/admin/bookstock/${bookId}`)
      setFormData({
        itemCode: data.itemCode || '',
        description: data.description || '',
        price: data.price || '',
        itemType: data.itemType || '',
        barCode: data.barCode || '',
        subsidized: String(data.subsidized || '0'),
        selectForStat: String(data.selectForStat || '0'),
      })
    } catch (error) {
      console.error('Error fetching book data:', error)
      toast.error('Failed to load book data.')
    } finally {
      setIsLoadingBookData(false)
    }
  }

  useEffect(() => {
    if (show && bookId) {
      fetchBookData()
      fetchCategories()
    }
  }, [show, bookId])

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      const response = await axios.put(
        `/api/v1/admin/bookstock/edit/${bookId}`,
        formData
      )
      toast.success('Updated successfully!')
      onUpdateBook(response.data.updatedBook)
      onClose()
    } catch (error) {
      console.error('Error updating book:', error)
      toast.error('Failed to update the book. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className='fs-5'>Edit Book</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoadingBookData ? (
          <p>Loading book data...</p>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='itemCode'>
              <Form.Label>Item Code</Form.Label>
              <Form.Control
                type='text'
                name='itemCode'
                value={formData.itemCode}
                onChange={handleChange}
                placeholder='Enter item code'
                required
              />
            </Form.Group>

            <Form.Group controlId='description' className='mt-3'>
              <Form.Label>Desc</Form.Label>
              <Form.Control
                type='text'
                name='description'
                value={formData.description}
                onChange={handleChange}
                placeholder='Enter description'
                required
              />
            </Form.Group>

            <Form.Group controlId='price' className='mt-3'>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type='number'
                name='price'
                value={formData.price}
                onChange={handleChange}
                onFocus={(e) => e.target.select()}
                placeholder='Enter price'
                required
              />
            </Form.Group>

            <Form.Group controlId='itemType' className='mt-3'>
              <Form.Label>Category</Form.Label>
              <Form.Select
                name='itemType'
                value={formData.itemType}
                onChange={handleChange}
                required
              >
                <option value=''>Select a category</option>
                {isCategoryLoading ? (
                  <option value='' disabled>
                    Loading...
                  </option>
                ) : (
                  categories.map((category) => (
                    <option key={category._id} value={category.categoryName}>
                      {category.categoryName}
                    </option>
                  ))
                )}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId='barCode' className='mt-3'>
              <Form.Label>Barcode</Form.Label>
              <Form.Control
                type='text'
                name='barCode'
                value={formData.barCode}
                onChange={handleChange}
                placeholder='Enter barcode'
              />
            </Form.Group>

            <Form.Group controlId='subsidized' className='mt-3'>
              <Form.Label className='d-block'>Subsidi</Form.Label>
              <div className='d-flex align-items-center'>
                <Form.Check
                  type='radio'
                  id='subsidized-yes'
                  name='subsidized'
                  value='1'
                  label='Yes'
                  checked={formData.subsidized === '1'}
                  onChange={handleChange}
                  inline
                />
                <Form.Check
                  type='radio'
                  id='subsidized-no'
                  name='subsidized'
                  value='0'
                  label='No'
                  checked={formData.subsidized === '0'}
                  onChange={handleChange}
                  inline
                />
              </div>
            </Form.Group>

            <Form.Group controlId='selectForStat' className='mt-3'>
              <Form.Label className='d-block'>Statistic</Form.Label>
              <div className='d-flex align-items-center'>
                <Form.Check
                  type='radio'
                  id='stat-yes'
                  name='selectForStat'
                  value='1'
                  label='Yes'
                  checked={formData.selectForStat === '1'}
                  onChange={handleChange}
                  inline
                />
                <Form.Check
                  type='radio'
                  id='stat-no'
                  name='selectForStat'
                  value='0'
                  label='No'
                  checked={formData.selectForStat === '0'}
                  onChange={handleChange}
                  inline
                />
              </div>
            </Form.Group>

            <div className='mt-4 text-end'>
              <Button
                variant='primary'
                type='submit'
                disabled={isSubmitting}
                className='me-4'
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </Button>
              <Button variant='secondary' onClick={onClose}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default AdminEditBook
