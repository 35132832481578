import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import useIsMobile from '../../../hooks/useIsMobile'
import { useAuth } from '../../../context/auth'

const SaleRecordList = () => {
  const navigate = useNavigate()
  const [auth] = useAuth()
  const userId = auth?.user?.id

  const isMobile = useIsMobile()
  const [groupedLists, setGroupedLists] = useState({})

  useEffect(() => {
    const fetchList = async () => {
      try {
        if (!userId) {
          console.error('No userId found. Please log in.')
          navigate('/login')
          return
        }

        const { data } = await axios.get('/api/v1/user/sale-record/lists', {
          params: { userId },
        })

        groupByDate(data)
      } catch (error) {
        console.error(
          'Failed to fetch lists:',
          error.response?.data || error.message
        )
      }
    }

    fetchList()
  }, [auth, navigate])

  const groupByDate = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const date = new Date(item.recordDate).toISOString().split('T')[0]
      if (!acc[date]) acc[date] = []
      acc[date].push(item)
      return acc
    }, {})
    setGroupedLists(groupedData)
  }

  const handleNewSale = () => {
    navigate('new')
  }

  const handleReturnHome = () => {
    if (auth?.user?.role === 2) {
      navigate('/main/dashboard')
    } else {
      navigate('/user/dashboard')
    }
  }

  const handleDelete = async (id) => {
    if (window.confirm('Sahkan Pemadaman?')) {
      try {
        await axios.delete(`/api/v1/user/sale-record/delete/${id}`)
        const updatedLists = Object.keys(groupedLists).reduce((acc, date) => {
          const filteredItems = groupedLists[date].filter(
            (item) => item._id !== id
          )
          if (filteredItems.length > 0) acc[date] = filteredItems
          return acc
        }, {})
        setGroupedLists(updatedLists)
        toast.success('Berjaya dipadamkan')
      } catch (error) {
        console.error('Failed to delete response:', error)
        toast.error('Failed to delete.')
      }
    }
  }

  return (
    <Layout title={'Rekod Jualan Buku'}>
      <div className='pt-3 mx-2'>
        <div
          className={`d-flex ${isMobile ? 'mb-2' : 'mb-3'} ${
            isMobile
              ? 'flex-column'
              : 'justify-content-between align-items-center'
          }`}
        >
          {isMobile && (
            <div className='d-flex justify-content-end w-100'>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm me-2'
                onClick={handleNewSale}
              >
                <i className='bi bi-plus-circle'></i> Jualan Buku
              </button>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          )}
          <div
            className={`w-100 ${isMobile ? 'mt-2' : ''}`}
            style={{
              textAlign: isMobile ? 'left' : 'inherit',
            }}
          >
            <h5
              className='mb-0'
              style={{
                color: '#556B2F',
              }}
            >
              Rekod Jualan Buku (PB.)
            </h5>
          </div>
          {!isMobile && (
            <div className='d-flex justify-content-end w-100'>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm me-4'
                onClick={handleNewSale}
              >
                <i className='bi bi-plus-circle'></i> Jualan Buku
              </button>
              <button
                type='button'
                className='btn btn-outline-secondary btn-sm'
                onClick={handleReturnHome}
              >
                <i className='bi bi-house-fill'></i>
              </button>
            </div>
          )}
        </div>

        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Buku</th>
              <th scope='col' className='text-end'>
                {isMobile ? 'Jumlah' : 'Harga'}
              </th>
              {!isMobile && (
                <>
                  <th scope='col' className='text-center'>
                    Qty
                  </th>
                  <th scope='col' className='text-end'>
                    Jumlah
                  </th>
                  <th className='text-end' style={{ whiteSpace: 'nowrap' }}>
                    Kad Respon
                  </th>
                  <th>Catatan</th>
                  <th></th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedLists).length > 0 ? (
              Object.entries(groupedLists).map(([date, items]) => {
                const totalQty = items.reduce((sum, item) => sum + item.qty, 0)
                const totalAmount = items.reduce(
                  (sum, item) => sum + item.qty * item.price,
                  0
                )

                return isMobile ? (
                  <React.Fragment key={date}>
                    <tr>
                      <td
                        colSpan='2'
                        className='bg-light fw-bold text-center'
                        style={{ color: '#0c432a' }}
                      >
                        {new Date(date).toLocaleDateString('en-GB', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })}
                      </td>
                    </tr>
                    {items.map((item) => (
                      <React.Fragment key={item._id}>
                        <tr>
                          <td>
                            {item.description}
                            <div>
                              {item.price.toFixed(2)} x {item.qty}
                            </div>
                          </td>
                          <td
                            className='text-end'
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            RM {(item.qty * item.price).toFixed(2)}
                            <div>
                              {item.contactData ? (
                                <Link
                                  to={'/user/respons'}
                                  className='text-decoration-none'
                                >
                                  {item.contactData.slice(-6)}
                                </Link>
                              ) : (
                                <i
                                  className='bi bi-trash-fill text-danger ms-auto'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleDelete(item._id)}
                                ></i>
                              )}
                            </div>
                          </td>
                        </tr>
                        {item.remark && (
                          <tr>
                            <td
                              colSpan={2}
                              className='py-1'
                              style={{ borderBottom: '1px solid #635a5a' }}
                            >
                              Catatan: {item.remark}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}

                    <tr className='fw-bold bg-light'>
                      <td>Jumlah Keseluruh</td>
                      <td className='text-end' style={{ whiteSpace: 'nowrap' }}>
                        RM {totalAmount.toFixed(2)}
                      </td>
                    </tr>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={date}>
                    <tr>
                      <td
                        colSpan='8'
                        className='bg-light fw-bold text-center'
                        style={{ color: '#0c432a' }}
                      >
                        {new Date(date).toLocaleDateString('en-GB', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })}
                      </td>
                    </tr>
                    {items.map((item) => (
                      <React.Fragment key={item._id}>
                        <tr>
                          <td>{item.description}</td>
                          <td className='text-end'>{item.price.toFixed(2)}</td>
                          <td className='text-center'>{item.qty}</td>
                          <td className='text-end'>
                            {(item.qty * item.price).toFixed(2)}
                          </td>
                          <td className='text-end'>
                            {item.contactData ? (
                              <Link
                                to={'/user/respons'}
                                className='text-decoration-none'
                              >
                                {item.contactData.slice(-6)}
                              </Link>
                            ) : null}
                          </td>
                          <td>{item.remark}</td>
                          <td className='text-end'>
                            {!item.contactData && (
                              <button
                                type='button'
                                className='btn btn-outline-danger btn-sm'
                                onClick={() => handleDelete(item._id)}
                              >
                                <i className='bi bi-trash-fill'></i>
                              </button>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                    <tr className='fw-bold bg-light'>
                      <td>Jumlah Keseluruh</td>
                      <td className='text-end'></td>
                      <td className='text-center'>{totalQty}</td>
                      <td className='text-end'>{totalAmount.toFixed(2)}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </React.Fragment>
                )
              })
            ) : (
              <tr>
                <td colSpan={isMobile ? '2' : '9'} className='text-center'>
                  Tiada rekod
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default SaleRecordList
